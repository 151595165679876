import {
  useAuthStore,
  useCheckIssuingPermissions,
  useIssuingPermissionsStore,
} from '@easy-expense/data-firestore-client';

export function useCheckIssuingPermissionsWeb() {
  const enabled = useCheckIssuingPermissions();
  const user = useAuthStore.use.currentUser();
  const ownerID = useIssuingPermissionsStore((s) => s.ownerID);
  return ownerID && (!user || enabled);
}
