import Bugsnag from '@bugsnag/js';
import { Analytics } from '@easy-expense/analytics-client';
import { useIsAdminRole, useIsManagerRole } from '@easy-expense/data-firestore-client';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, Navigate } from 'react-router-dom';

import { auth } from '../firebase/app';

export const RequireManager = ({ children }: { children: JSX.Element }) => {
  const [user, loading] = useAuthState(auth);
  const isAdmin = useIsAdminRole();
  const isManager = useIsManagerRole();
  const canManageTeam = isAdmin || isManager;

  if (!loading && !canManageTeam) {
    return <Navigate to="/transactions" replace />;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  return children;
};
