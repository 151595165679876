import {
  useWorkspaceKeysStore,
  useOwnWorkspaceMembershipPaths,
  useCachedWorkspace,
} from '@easy-expense/data-firestore-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { formatNameAsInitials, getIDsFromWorkspacePath } from '@easy-expense/utils-shared';
import React from 'react';
import { Image } from 'react-native';

const WorkspaceRow: React.FC<
  React.PropsWithChildren<{
    onPress: () => void;
    border?: boolean;
    path?: string;
    showExpand?: boolean;
  }>
> = ({ onPress, border, path, showExpand = false }) => {
  const { workspaceID } = getIDsFromWorkspacePath(path ?? '');
  const workspace = useCachedWorkspace(workspaceID);
  const abbr = formatNameAsInitials(workspace.name);
  return (
    <Layout.Row bg="brandPrimaryLight" style={{ width: '100%' }}>
      <Layout.PressableRow
        onClick={onPress}
        radius={border ? 6 : 0}
        px={16}
        py={8}
        align
        bg="inputBackground"
        style={{ width: '100%' }}
      >
        <Layout.Column
          px={workspace.logoURL ? 0 : 8}
          py={workspace.logoURL ? 0 : 8}
          bg="brandPrimaryLight"
          radius={6}
          center
        >
          {workspace.logoURL ? (
            <Image source={{ uri: workspace.logoURL }} style={{ height: 30, width: 30 }} />
          ) : (
            <OpenSans.Primary weight="bold-700" size="xs-12">
              {abbr}
            </OpenSans.Primary>
          )}
        </Layout.Column>
        <Spacer.Horizontal />
        <Layout.Column grow>
          <OpenSans.Primary
            size="s-16"
            weight="bold-700"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              maxWidth: 140,
              alignSelf: 'flex-start',
            }}
          >
            {workspace.name}
          </OpenSans.Primary>
        </Layout.Column>

        {showExpand ? <Icon name="chevron-expand-outline" size={16} color="gray" /> : null}
      </Layout.PressableRow>
    </Layout.Row>
  );
};

export const WorkspaceSwitcher: React.FC = () => {
  const [showSelector, setShowSelector] = React.useState(false);
  const { setCurrentWorkspacePath } = useWorkspaceKeysStore();
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());

  const workspacePaths = useOwnWorkspaceMembershipPaths();

  return (
    <Layout.Column style={{ width: '100%' }}>
      <WorkspaceRow
        onPress={() => {
          setShowSelector(!showSelector);
        }}
        path={currentWorkspacePath}
        border
        showExpand={true}
      />

      {showSelector && workspacePaths && (
        <Layout.Column style={{ width: '100%' }}>
          <Layout.DropdownColumn style={{ width: '100%' }}>
            <Spacer.Vertical size={4} />
            <Layout.Column shadow border={[1, 'solid', 'grayLight']}>
              {workspacePaths.map((ws) => {
                return (
                  <WorkspaceRow
                    key={ws}
                    path={ws}
                    onPress={() => {
                      setCurrentWorkspacePath(ws);
                      setShowSelector(false);
                    }}
                  />
                );
              })}
            </Layout.Column>
          </Layout.DropdownColumn>
        </Layout.Column>
      )}
    </Layout.Column>
  );
};
