import { currentUser, StripeClientWrappers } from '@easy-expense/auth-client';
import {
  useIssuingPermissionsStore,
  useIssuingStore,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { Layout, OpenSans, Separator } from '@easy-expense/ui-web-core';
import { EasyExpenseIssuing, EntityIDTypes } from '@easy-expense/utils-shared';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';
// import { FlatList, Linking } from 'react-native';
import { FlatList } from 'react-native';
import { useParams, useNavigate } from 'react-router-dom';
// import { Link, useParams, useNavigate } from 'react-router-dom';

import { AccountDashboard } from '../components/Issuing/AccountDashboard.component';
import { AddNewSwitch } from '../components/Issuing/AddNewSwitch.component';
import { BalanceDashboard } from '../components/Issuing/BalanceDashboard.component';
import { IssuingRowListItem } from '../components/Issuing/IssuingRowListItem.component';
import { ListFooter } from '../components/Issuing/ListFooter.component';

// const entityOptions = [
//   'balance',
//   'account',
//   'cardholders',
//   'cards',
//   'transactions',
//   'authorizations',
//   'sources',
// ] as unknown as (keyof EasyExpenseIssuing.ClientAPI.GetMethods)[];

export const Issuing: React.FC = () => {
  const { currentOrganizationKey } = useWorkspaceKeysStore();
  const organizationID = currentOrganizationKey as EntityIDTypes.EE.OrganizationID;
  const navigate = useNavigate();
  const params = useParams<{ entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods }>();
  const entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods = params.entity || 'account';

  const [addNew, setAddNew] = React.useState<boolean>(false);

  const {
    loadingState,
    account,
    balance,
    refreshEntity,
    cards,
    cardholders,
    sources,
    transactions,
    authorizations,
  } = useIssuingStore();

  const connectedAccountID = useIssuingPermissionsStore(
    (s) => s.connectedAccountID || ('' as EntityIDTypes.ConnectedAccountID),
  );

  const listData: ({ object: string; id: string } & DoNotFixMeIAmAny)[] | undefined =
    React.useMemo(() => {
      if (entity === 'cards') {
        return cards?.data;
      }
      if (entity === 'cardholders') {
        return cardholders?.data;
      }
      if (entity === 'transactions') {
        return transactions?.data;
      }
      if (entity === 'authorizations') {
        return authorizations?.data;
      }
      if (entity === 'sources') {
        return sources?.data;
      }
      return undefined;
    }, [entity, cards, cardholders, transactions, authorizations, sources]);

  const createConnectedAccount = React.useCallback(() => {
    StripeClientWrappers.Account.updateOrCreateAccount({
      metadata: {
        organizationID: currentOrganizationKey as EntityIDTypes.EE.OrganizationID,
        userID: (currentUser()?.uid ?? '') as EntityIDTypes.EE.UserID,
      },
    }).then(() => {
      navigate('/issuing/onboarding');
    });
  }, [navigate, organizationID]);

  React.useEffect(() => {
    setAddNew(false);
    refreshEntity(entity, {});
  }, [entity]);

  function renderDashboard() {
    if (typeof loadingState === 'string') {
      return <OpenSans.Primary>{loadingState}</OpenSans.Primary>;
    }
    if (!connectedAccountID) {
      return (
        <OpenSans.Pressable onClick={createConnectedAccount}>
          Sign up for Card Issuing
        </OpenSans.Pressable>
      );
    }

    if (entity === 'account' && account) {
      return <AccountDashboard account={account} />;
    }

    if (entity === 'balance' && balance) {
      return <BalanceDashboard balance={balance} />;
    }
    if (entity === 'cards' && listData) {
      return null;
    }

    if (loadingState === true && connectedAccountID) {
      return (
        <Layout.Row px py>
          <OpenSans.Primary>Refreshing...</OpenSans.Primary>
        </Layout.Row>
      );
    }
    return null;
  }

  return (
    <>
      <Layout.Column px={16} style={{ height: '100%' }}>
        {/* <Layout.Row>
          {connectedAccountID
            ? entityOptions.map((eO) => (
                <Layout.Column px py bg={eO === entity ? 'brandPrimaryLight' : undefined} key={eO}>
                  <Link
                    to={`/issuing/${eO}`}
                    style={{ textDecoration: 'none', textOverflow: 'ellipsis' }}
                  >
                    <OpenSans.Pressable>{eO}</OpenSans.Pressable>
                  </Link>
                </Layout.Column>
              ))
            : null}
        </Layout.Row> */}
        <Layout.Column px py style={{ flex: 1, overflowY: 'scroll' }}>
          {renderDashboard()}
        </Layout.Column>
      </Layout.Column>
      {connectedAccountID ? (
        <AddNewSwitch
          modalOpen={addNew}
          setModalOpen={setAddNew}
          entity={entity}
          accountID={connectedAccountID}
        />
      ) : null}
    </>
  );
};
