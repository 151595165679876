import { resetPassword } from '@easy-expense/auth-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './Button.components';
import { ErrorText } from './Form/Fields/ErrorText.component';
import { LabelTextField } from './LabelTextField.component';
import { EmailSchema, PASSWORD_LENGTH } from './SignUp.form';
import { SocialAuth } from './SocialAuth.component';
import { login } from '../firebase/auth';

export const LoginForm: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [email, setEmail] = React.useState<string>('');
  const [isDirtyEmail, setIsDirtyEmail] = React.useState<boolean>(false);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);

  const [password, setPassword] = React.useState<string>();
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [isDirtyPassword, setIsDirtyPassword] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const onSocialSuccess = () => {
    onSuccess();
    return null;
  };

  const signIn = async () => {
    if (email && emailValid && password && passwordValid) {
      const user = await login(email, password);

      if (user) {
        onSuccess();
      }
    }
  };

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(email);
    setEmailValid(isValid.success);
  }, [email]);

  React.useEffect(() => {
    const isValid = password?.length != undefined && password?.length >= PASSWORD_LENGTH;
    setPasswordValid(isValid);
  }, [password]);

  return (
    <Layout.Column grow radius style={{ width: '100%' }}>
      <Layout.Row center>
        <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
          {getTranslation('Login')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={24} />
      <LabelTextField label="">
        <Layout.Row align>
          <Icon name="mail-outline" size={24} color={theme.colors.primary} />
          <Spacer.Horizontal />
          <OpenSans.Input
            name="email"
            value={email}
            type="text"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder="username@email.com"
            grow
            style={{ outline: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsDirtyEmail(true);
              setEmail(event.target.value);
            }}
            autoComplete="off"
          />
        </Layout.Row>
        {isDirtyEmail && !emailValid ? (
          <ErrorText>
            {email ? getTranslation('Invalid email') : getTranslation('Email is required')}
          </ErrorText>
        ) : null}
      </LabelTextField>
      <Spacer.Vertical size={12} />
      <LabelTextField label="">
        <Layout.Row align>
          <Icon name="lock-closed-outline" size={24} color={theme.colors.primary} />
          <Spacer.Horizontal />
          <OpenSans.Input
            name="password"
            value={password}
            type="password"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder="***********"
            grow
            style={{ outline: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsDirtyPassword(true);
              setPassword(event.target.value);
            }}
            autoComplete="off"
          />
        </Layout.Row>
        {isDirtyPassword && !passwordValid ? (
          <ErrorText>
            {password
              ? getTranslation(`Should be at least ${PASSWORD_LENGTH} characters`)
              : getTranslation('Password is required')}
          </ErrorText>
        ) : null}
      </LabelTextField>
      <Spacer.Vertical size={12} />

      <Layout.PressableRow
        py
        onClick={() => {
          if (email) {
            resetPassword(email);

            alert(
              `${getTranslation('Reset Email Sent')}\n${getTranslation(
                'Please check your inbox and spam folder for reset instructions',
              )}`,
            );
          } else {
            alert(`${getTranslation('Please enter an email address')}`);
          }
        }}
        center
        bg="navHeaderBackground"
      >
        <OpenSans.Primary>{getTranslation('Forgot your password?')} </OpenSans.Primary>
        <Spacer.Horizontal />
        <OpenSans.Pressable weight="bold-700">{getTranslation('Reset')}</OpenSans.Pressable>
      </Layout.PressableRow>
      <Spacer.Vertical size={12} />

      <Layout.Row center>
        <Layout.Column grow py={1} bg="grayXLight" />
        <Spacer.Horizontal />
        <OpenSans.Secondary size="s-16">{getTranslation('OR')}</OpenSans.Secondary>
        <Spacer.Horizontal />
        <Layout.Column grow py={1} bg="grayXLight" />
      </Layout.Row>
      <Spacer.Vertical size={12} />
      <SocialAuth onSuccess={onSocialSuccess} />
      <Spacer.Vertical size={48} />
      <Button.Primary
        px
        py
        radius
        center
        content={getTranslation('Login')}
        onClick={() => signIn()}
      />
      <Spacer.Vertical size={24} />
      <Layout.PressableRow bg="navHeaderBackground" center onClick={() => navigate('/signUp')}>
        <OpenSans.Primary>
          {getTranslation('Need an account?')}
          <Spacer.Horizontal />
          <OpenSans.Pressable weight="bold-700">{getTranslation('Sign Up')}</OpenSans.Pressable>
        </OpenSans.Primary>
      </Layout.PressableRow>
    </Layout.Column>
  );
};
