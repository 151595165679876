import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import Checkbox from '@mui/material/Checkbox';

import { ExportSettings } from './types';

export const ExportSettingComponent: React.FC<{
  settings: ExportSettings;
  setSettings: (settings: ExportSettings) => void;
}> = ({ settings, setSettings }) => {
  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Settings')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={16} />

      <Layout.Row align>
        <Layout.Column>
          <Layout.Row align>
            <OpenSans.Primary size={16} weight="bold-700">
              {getTranslation('Download Receipt Images')}
            </OpenSans.Primary>
          </Layout.Row>
          <Layout.Row align>
            <OpenSans.Secondary size={16} weight="bold-700">
              {getTranslation('Receipt links are always in the CSV')}
            </OpenSans.Secondary>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={24} />
        <Layout.Column>
          <Checkbox
            style={{ padding: 0 }}
            checked={settings.downloadReceipts}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const downloadReceipts = event.target.checked;
              setSettings({ ...settings, downloadReceipts });
            }}
          />
        </Layout.Column>
      </Layout.Row>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <Layout.Column>
          <Layout.Row align>
            <OpenSans.Primary size={16} weight="bold-700">
              {getTranslation('Include Archived')}
            </OpenSans.Primary>
          </Layout.Row>
          <Layout.Row align>
            <OpenSans.Secondary size={16} weight="bold-700">
              {getTranslation('Receipt links are always included')}
            </OpenSans.Secondary>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={24} />
        <Layout.Column>
          <Checkbox
            style={{ padding: 0 }}
            checked={settings.includeArchived}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const includeArchived = event.target.checked;
              setSettings({ ...settings, includeArchived });
            }}
          />
        </Layout.Column>
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
