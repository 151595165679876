import React from 'react';
import { useParams } from 'react-router-dom';

import { ExpenseEdit } from '../components/Expense/Edit/ExpenseEdit.component';

export const ExpenseEditPage: React.FC = () => {
  const { expenseKey } = useParams();

  return <ExpenseEdit expenseKey={expenseKey} />;
};
