import { useIsAdminRole, useIsManagerRole } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ExpenseActions: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout.Row align>
      <OpenSans.Primary size={32} weight="bold-700">
        {getTranslation('Transactions')}
      </OpenSans.Primary>
      <Spacer.Horizontal size={32} />
      <Layout.PressableRow
        onClick={() => navigate('/expense')}
        bg="inputBackground"
        border={[1, 'solid', 'inputBorder']}
        py={4}
        px
        radius={100}
        align
      >
        <Icon name="add-outline" size={16} color={theme.colors.primary} />
        <Spacer.Horizontal size={8} />
        <OpenSans.Primary>{getTranslation('Add Transaction')}</OpenSans.Primary>
      </Layout.PressableRow>
    </Layout.Row>
  );
};

export const ReceiptOptions: React.FC = () => (
  <Layout.Row>
    {/* https://linear.app/easy-expense/issue/EE-2664/implement-receipt-upload-and-scan-in-transactions-page */}
    {/* <Layout.PressableRow */}
    {/*   px={24} */}
    {/*   py */}
    {/*   border={[1, 'solid', 'brandPrimary']} */}
    {/*   radius={8} */}
    {/*   align */}
    {/*   bg="brandPrimaryXLight" */}
    {/* > */}
    {/*   <Icon name="receipt-outline" size={24} color={theme.colors.brandPrimary} /> */}
    {/*   <Spacer.Horizontal size={24} /> */}
    {/*   <Layout.Column> */}
    {/*     <OpenSans.Pressable weight="bold-700" size="s-16"> */}
    {/*       {getTranslation('Drag & drop receipts')} */}
    {/*     </OpenSans.Pressable> */}
    {/*     <OpenSans.Pressable size="xs-12"> */}
    {/*       {getTranslation('We will scan details automatically')} */}
    {/*     </OpenSans.Pressable> */}
    {/*   </Layout.Column> */}
    {/* </Layout.PressableRow> */}
    {/* <Spacer.Horizontal size={16} /> */}
    <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
      <Icon name="mail-outline" size={24} color={theme.colors.primary} />
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <OpenSans.Primary weight="bold-700" size="s-16">
          {getTranslation('Import emailed receipts')}
        </OpenSans.Primary>
        <OpenSans.Primary size="xs-12">
          {getTranslation('Forward to: upload@easy-expense.com')}
        </OpenSans.Primary>
      </Layout.Column>
    </Layout.Row>
  </Layout.Row>
);

export const TransactionListHeader: React.FC<object> = () => {
  return (
    <Layout.Column>
      <ExpenseActions />
      <Spacer.Vertical size={12} />
      <ReceiptOptions />
      <Spacer.Vertical size={16} />
      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
