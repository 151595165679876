import {
  DEFAULT_SORT_ORDER,
  useMultiple,
  useWorkspaceSettings,
} from '@easy-expense/data-firestore-client';
import { TripClassValue, LocationValue, VehicleValue, Trip } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { useIntlStore } from '@easy-expense/intl-client';
import { Layout, Mono, OpenSans, Separator, Spacer } from '@easy-expense/ui-web-core';
import {
  calculateDeduction,
  dateFormatSkeleton,
  hueToColor,
  orderedSectionsByDate,
} from '@easy-expense/utils-shared';
import { darken, lighten } from 'polished';
import React from 'react';
import { Transition } from 'react-transition-group';

import { useWindowDimensions } from '../hooks/useWindow';

const duration = 300;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
};

const transitionStyles: { [key: string]: object } = {
  entering: { position: 'absolute', width: 0, right: 0, top: 0, bottom: 0 },
  entered: { position: 'absolute', width: 600, right: 0, top: 0, bottom: 0 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const TripClassBadge: React.FC<React.PropsWithChildren<{ tripClass?: TripClassValue }>> = ({
  tripClass,
}) => {
  if (!tripClass) {
    return null;
  }

  const tripClassColor = hueToColor(tripClass.color ?? 0);

  return (
    <Layout.Row style={{ flexGrow: 1, flexShrink: 1 }}>
      <Layout.Row
        radius={100}
        center
        style={{ backgroundColor: lighten(0.33, `${tripClassColor}`) }}
      >
        <OpenSans.Primary
          weight="bold-700"
          px
          size="s-16"
          style={{
            overflow: 'hidden',
            maxHeight: 26,
            justifySelf: 'center',
            color: darken(0.25, `${tripClassColor}`),
          }}
        >
          {`${tripClass.icon} ${tripClass.name}`}
        </OpenSans.Primary>
      </Layout.Row>
    </Layout.Row>
  );
};

const TripLocations: React.FC<{
  startLocation?: LocationValue;
  endLocation?: LocationValue;
  containerWidth: number;
}> = ({ startLocation, endLocation, containerWidth }) => {
  return (
    <Layout.Column>
      <OpenSans.Primary
        style={{
          overflow: 'hidden',
          maxHeight: 24,
          minWidth: 100,
          maxWidth: 250,
          width: containerWidth > 750 ? 300 : undefined,
        }}
      >
        🟢 {startLocation?.name}
      </OpenSans.Primary>
      <OpenSans.Primary
        style={{
          overflow: 'hidden',
          maxHeight: 24,
          minWidth: 100,
          maxWidth: 250,
          width: containerWidth > 750 ? 300 : undefined,
        }}
      >
        🔴 {endLocation?.name}
      </OpenSans.Primary>
    </Layout.Column>
  );
};

type TripWithData = Trip & {
  tripClassValue?: TripClassValue;
  startLocationValue?: LocationValue;
  endLocationValue?: LocationValue;
  vehicleValue?: VehicleValue;
};

const TripRow: React.FC<
  React.PropsWithChildren<{ trip: TripWithData; containerWidth: number }>
> = ({ trip, containerWidth }) => {
  const { formatDate, formatCurrency } = useIntlStore();
  const { height } = useWindowDimensions();

  const spacerSize = containerWidth < 600 ? 8 : 16;
  const [modalOpen, setModalOpen] = React.useState(false);
  const { data: workspaceSettingsData } = useWorkspaceSettings();

  const workspaceRateDetails = Data.rateDetails.use();
  const countryRateDetails = Data.countryRateDetails.use();
  const tripClass = Data.tripClasses.useByKey(trip.tripClass);

  const deduction = React.useMemo(() => {
    const countryRates = countryRateDetails.filter(
      (rate) => rate.countryCode === workspaceSettingsData.settings?.countryCode,
    );

    const deductionAmount = calculateDeduction({
      trip: {
        ...trip,
        parking: trip.parking ?? undefined,
        tolls: trip.tolls ?? undefined,
      },
      workspaceRateDetails,
      tripClass,
      countryRateDetails: countryRates,
    });

    return deductionAmount.deduction;
  }, [trip, tripClass, workspaceRateDetails, countryRateDetails, workspaceSettingsData]);

  return (
    <Layout.Column style={{ width: '100%' }}>
      <Layout.Row>
        <Layout.Row py={8} align grow>
          <Layout.Column align style={{ width: 48, flexShrink: 0 }}>
            <OpenSans.Primary size={'xs-12'}>
              {formatDate(new Date(trip.date), {
                skeleton: dateFormatSkeleton.month,
              })}
            </OpenSans.Primary>
            <OpenSans.Primary size="l-20">
              {formatDate(new Date(trip.date), {
                skeleton: dateFormatSkeleton.day,
              })}
            </OpenSans.Primary>
          </Layout.Column>

          <Spacer.Horizontal size={spacerSize} style={{ flexShrink: 0 }} />

          <TripLocations
            startLocation={trip.startLocationValue}
            endLocation={trip.endLocationValue}
            containerWidth={containerWidth}
          />

          <Spacer.Horizontal size={spacerSize} style={{ flexShrink: 0 }} />

          <TripClassBadge tripClass={trip.tripClassValue} />

          <Spacer.Horizontal size={spacerSize} style={{ flexShrink: 0 }} />

          <Layout.Column align="flex-end" style={{ flexGrow: 1, flexShrink: 10 }}>
            <OpenSans.Primary>{`${Number(trip.miles).toFixed(2)}mi`}</OpenSans.Primary>
            <Mono.Primary
              weight="bold-700"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              -{formatCurrency(deduction)}
            </Mono.Primary>
          </Layout.Column>
          <Spacer.Horizontal size={12} />
        </Layout.Row>
      </Layout.Row>
      <Separator.Horizontal />

      <Transition in={modalOpen} timeout={0}>
        {(state) => {
          if (modalOpen) {
            return (
              <>
                <Layout.Column
                  style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}
                  bg="modalBackground"
                  onClick={() => setModalOpen(false)}
                />
                <Layout.Column style={{ ...defaultStyle, ...transitionStyles[state], flex: 1 }}>
                  <Layout.Column bg="grayXLight" center style={{ height }} py>
                    <Spacer.Vertical size={12} />
                    <Layout.PressableRow
                      onClick={() => setModalOpen(false)}
                      py
                      center
                      bg={'brandPrimary'}
                      border={[0, 'solid', 'gray']}
                      radius={4}
                      style={{ width: 250 }}
                    >
                      <OpenSans.Inverse weight="bold-700">Close</OpenSans.Inverse>
                    </Layout.PressableRow>
                  </Layout.Column>
                </Layout.Column>
              </>
            );
          }
        }}
      </Transition>
    </Layout.Column>
  );
};

export const TripList: React.FC<React.PropsWithChildren<{ filter?: (e: Trip) => boolean }>> = ({
  filter = () => true,
}) => {
  const { formatDate } = useIntlStore();
  const { width } = useWindowDimensions();

  const [allTrips = []] = useMultiple('trip', {
    orderBy: [...DEFAULT_SORT_ORDER],
  });
  const tripClasses = Data.tripClasses.use();
  const locations = Data.locations.use();
  const vehicles = Data.vehicles.use();
  const tripsWithData: TripWithData[] = allTrips.map((t) => {
    const tripClassValue = tripClasses?.find((v) => v.key === t.tripClass)?.value as TripClassValue;
    const startLocationValue = locations?.find((c) => c.key === t.startLocation)
      ?.value as LocationValue;
    const endLocationValue = locations?.find((c) => c.key === t.endLocation)
      ?.value as LocationValue;
    const vehicleValue = vehicles?.find((pm) => pm.key === t.vehicle)?.value as VehicleValue;

    return {
      ...t,
      tripClassValue,
      startLocationValue,
      endLocationValue,
      vehicleValue,
    };
  });
  const filteredExpenses = tripsWithData.filter(filter);
  const sortedSections = orderedSectionsByDate(filteredExpenses, (date) =>
    formatDate(new Date(date), { skeleton: 'MMMMy' }),
  );

  const targetRef = React.useRef<HTMLDivElement | null>(null);
  const [componentWidth, setComponentWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      setComponentWidth(targetRef.current.offsetWidth);
    }
  }, [width]);

  return (
    <Layout.Column px style={{ width: '100%' }} ref={targetRef}>
      {sortedSections.map((section, index) => {
        return (
          <Layout.Column key={`section-${index}`}>
            <OpenSans.Primary py weight="bold-700" size="l-20">
              {section.title}
            </OpenSans.Primary>
            {section.data.map((trip, index) => {
              return <TripRow key={`trip-${index}`} trip={trip} containerWidth={componentWidth} />;
            })}
          </Layout.Column>
        );
      })}
    </Layout.Column>
  );
};
