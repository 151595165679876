import { WebEnv } from '@easy-expense/env-mobile';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';

let app = getApp();
if (!app) {
  app = initializeApp(WebEnv.firebase);
}

export const auth = getAuth(app);
export const db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
