import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import { LoginForm } from '../components/Login.form';
import {
  OnboardingActionColumn,
  OnboardingBackdrop,
} from '../components/Onboarding/OnboardingBackdrop.component';
import { auth } from '../firebase/app';
import { useIsMobile, useWindowDimensions } from '../hooks/useWindow';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  const location = useLocation();
  // @ts-expect-error
  const from = location.state?.from?.pathname || '/';

  React.useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  const onSuccess = () => {
    navigate(from, { replace: true });
  };

  const isMobile = useIsMobile();

  if (loading) {
    return (
      <Layout.Column style={{ minHeight: '100vh' }} bg="brandPrimaryXLight" center>
        <OpenSans.Primary
          size={isMobile ? 'l-20' : '2xl-32'}
          weight="light-300"
          style={{ alignSelf: 'center' }}
        >
          Loading...
        </OpenSans.Primary>
      </Layout.Column>
    );
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn width={430}>
        <LoginForm onSuccess={onSuccess} />
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
