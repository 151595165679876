import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';

import { auth } from '../firebase/app';

export const RequireNotAuth = ({ children }: { children: JSX.Element }) => {
  const [user] = useAuthState(auth);

  if (user) {
    return <Navigate to="/transactions" replace />;
  }

  return children;
};
