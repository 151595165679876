import React from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState({ height: 0, width: 1000 });

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (window) {
      setWindowDimensions(getWindowDimensions);
    }
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useIsMobile() {
  const { width } = useWindowDimensions();
  return width < 600;
}
