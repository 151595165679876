import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { useDownloadReport } from '../../hooks/downloads/useDownloadReport';
import LoadingSpinner from '../LoadingSpinner.component';

export const DownloadReportCSVButton: React.FC<{ reportID?: string }> = ({ reportID }) => {
  const { submitDownloadRequest, sharedResource } = useDownloadReport(reportID);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    directDownloadLink();
  }, [sharedResource]);

  function directDownloadLink() {
    if (sharedResource?.shareURL) {
      const link = document.createElement('a');
      link.href = sharedResource?.shareURL;
      link.download = sharedResource.shareURL || 'downloaded-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(sharedResource?.shareURL);
      setLoading(false);
    }
  }

  function startDownload() {
    setLoading(true);
    if (sharedResource?.shareURL) {
      directDownloadLink();
    } else {
      submitDownloadRequest();
    }
  }

  if (!reportID) {
    return null;
  }

  return (
    <Layout.PressableRow
      onClick={startDownload}
      bg="inputBackground"
      border={[1, 'solid', 'inputBorder']}
      py={4}
      px
      radius={100}
      center
      style={{ minWidth: 130 }}
      disabled={loading}
    >
      {!loading ? (
        <Icon name="cloud-download-outline" size={16} color={theme.colors.primary} />
      ) : (
        <LoadingSpinner size={16} />
      )}
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary>{getTranslation('Download CSV')}</OpenSans.Primary>
      <Spacer.Horizontal size={8} />
    </Layout.PressableRow>
  );
};
