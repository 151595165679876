import { currentUser } from '@easy-expense/auth-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import { DownloadReportRequestSchema, ReportWithData } from '@easy-expense/data-schema-v2';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout } from '@easy-expense/ui-web-core';
import { onSnapshot } from '@firebase/firestore';
import _ from 'lodash';
import { MRT_Row } from 'material-react-table';
import React from 'react';

import { useCreateDownloadReportRequest } from '../../hooks/firebase/useDownloadRequest';
import LoadingSpinner from '../LoadingSpinner.component';

export const ReportDownloadColumnCell: React.FC<{ row: MRT_Row<ReportWithData> }> = ({ row }) => {
  const user = currentUser();
  const report = row.original;
  const create = useCreateDownloadReportRequest();
  const [working, setWorking] = React.useState(false);
  const [shareURL, setShareURL] = React.useState<string | null>(null);

  async function onPressRow(e: React.MouseEvent) {
    e.stopPropagation();
    if (shareURL) {
      window.open(shareURL);
      setShareURL(null);
      return;
    }
    if (!create) {
      alert('An error occurred, please try again');
      return;
    }
    setWorking(true);
    const reqDoc = await create(
      DownloadReportRequestSchema.parse({
        reportID: report.key,
        ...entityFields('create', user?.uid ?? ''),
      }),
    );

    onSnapshot(reqDoc, {
      next: async (snap) => {
        const newData = snap.data();
        if (newData?.error) {
          alert('There was an error exporting your data');
        }
        if (newData?.documentURL) {
          const shareWindow = window.open(newData.documentURL);
          if (!shareWindow || shareWindow.closed || typeof shareWindow.closed == 'undefined') {
            setShareURL(newData.documentURL);
          }
          setWorking(false);
        }
      },
      error: () => {
        setWorking(false);
        alert('There was an error creating the request');
      },
    });
  }
  return (
    <Layout.Row style={{ width: 32 }}>
      <Layout.PressableRow
        align
        center
        style={{ width: 32, height: 32 }}
        radius={100}
        onClick={onPressRow}
        bg="inputBackground"
        border={[1, 'solid', 'inputBorder']}
      >
        {!working ? (
          <Icon name="cloud-download-outline" size={12} color={theme.colors.primary} />
        ) : (
          <LoadingSpinner size={16} />
        )}
      </Layout.PressableRow>
    </Layout.Row>
  );
};
