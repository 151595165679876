import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

export const ExportHeader: React.FC<{ onDownload: () => void }> = ({ onDownload }) => {
  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={32} weight="bold-700">
          {getTranslation('Export')}
        </OpenSans.Primary>
        <Spacer.Horizontal size={32} />
        <Layout.PressableRow
          onClick={() => onDownload()}
          bg="inputBackground"
          border={[1, 'solid', 'brandPrimary']}
          py={4}
          px
          radius={100}
          align
        >
          <Icon name="download-outline" size={16} color={theme.colors.brandPrimary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary style={{ color: theme.colors.brandPrimary }}>
            {getTranslation('Download')}
          </OpenSans.Primary>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
