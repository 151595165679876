import {
  useCachedMembers,
  useIsAdminRole,
  useIsManagerRole,
} from '@easy-expense/data-firestore-client';
import { Expense } from '@easy-expense/data-schema-v2';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { dateFormatSkeleton, getUserDisplayName } from '@easy-expense/utils-shared';
import { MenuItem, TextField } from '@mui/material';
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import React from 'react';

import { CategoryBadge } from './CategoryBadge.component';
import { ExpenseWithData, Reports, Vendor } from './ExpenseRow.component';
import { ReceiptButton } from './ReceiptButton.component';
import { ReceiptSideModal } from './ReceiptSideModal.component';
import { useMappedExpenseData } from './helpers';

interface MaterialExpenseTableProps {
  onRowClick: (expenseKey: string) => void;
  filter?: (e: Expense) => boolean;
  showBulk?: boolean;
  header?: React.ReactNode;
  filterUser?: string;
  selected?: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  isReport?: boolean;
  showSearch?: boolean;
}

export const ExpenseTable: React.FC<React.PropsWithChildren<MaterialExpenseTableProps>> = ({
  onRowClick,
  header,
  showBulk = false,
  selected = [],
  setSelected = () => {},
  isReport = false,
  showSearch = true,
}) => {
  const { formatDate, formatCurrency } = useIntlStore();
  const isAdmin = useIsAdminRole();
  const isManager = useIsManagerRole();
  const canFilter = isAdmin || isManager;
  const members = useCachedMembers();
  const filterMembers = React.useMemo(
    () => (canFilter ? Object.values(members).map((member) => getUserDisplayName(member)) : []),
    [canFilter, members],
  );

  const [showModal, setShowModal] = React.useState(false);
  const [receiptURI, setReceiptURI] = React.useState('');

  function onReceiptButtonClick(uri: string) {
    setReceiptURI(uri);
    setShowModal(true);
  }

  const expensesWithData = useMappedExpenseData();

  const columns = React.useMemo<MRT_ColumnDef<ExpenseWithData>[]>(
    () => [
      {
        accessorFn: (row) => new Date(row.date),
        id: 'date',
        filterVariant: 'date-range',
        filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        header: getTranslation('Date'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Date')}</OpenSans.Secondary>
        ),
        maxSize: 10,
        Cell: ({ row }) => {
          const date = row.original.date;
          return (
            <Layout.Column style={{ width: 60 }}>
              <OpenSans.Secondary size={'s-12'}>
                {formatDate(new Date(date), {
                  skeleton: dateFormatSkeleton.abbreviatedNoYear,
                })}
              </OpenSans.Secondary>
            </Layout.Column>
          );
        },
      },
      {
        accessorKey: 'vendorValue.name',
        header: getTranslation('To/From'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('To/From')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          const vendorValue = row.original.vendorValue;
          return <Vendor vendor={vendorValue} />;
        },
      },
      {
        accessorKey: 'categoryValue.name',
        header: getTranslation('Category'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Category')}</OpenSans.Secondary>
        ),
        maxSize: 40,
        Cell: ({ row }) => {
          const categoryValue = row.original.categoryValue;
          return <CategoryBadge category={categoryValue} />;
        },
      },
      {
        accessorFn: (row) => row.creatorDisplayName,
        accessorKey: 'creatorDisplayName',
        header: getTranslation('Created By'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Created By')}</OpenSans.Secondary>
        ),
        filterSelectOptions: filterMembers,
        filterVariant: 'multi-select',
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.creatorDisplayName}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },

      {
        accessorKey: 'paymentMethodValue.name',
        header: getTranslation('Account'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Account')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ renderedCellValue }) => {
          return (
            <OpenSans.Primary size="s-12" color={renderedCellValue ? undefined : 'secondary'}>
              {renderedCellValue ? renderedCellValue : 'None'}
            </OpenSans.Primary>
          );
        },
      },
      {
        accessorKey: 'reports',
        accessorFn: (row) => row.reports.map((r) => r.name).join(''),
        filterFn: 'arrIncludes',
        header: getTranslation('Report'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Reports')}</OpenSans.Secondary>
        ),

        size: 100,
        Cell: ({ row }) => {
          const reports = row.original.reports;
          return (
            <Layout.Row>
              <Reports reports={reports} />
            </Layout.Row>
          );
        },
      },

      {
        accessorKey: 'total',
        header: getTranslation('Amount'),
        filterFn: 'between',
        muiTableHeadCellProps: {
          align: 'right',
        },
        Header: () => (
          <OpenSans.Secondary size={'s-12'} style={{ paddingLeft: 6 }}>
            {getTranslation('Amount')}
          </OpenSans.Secondary>
        ),
        size: 50,
        Cell: ({ row }) => {
          const value = row.original.total;
          const [dollars, cents] = formatCurrency(-1 * value).split('.');
          return (
            <Layout.Column align="flex-end">
              <OpenSans.Primary
                style={{ whiteSpace: 'nowrap' }}
                size="s-12"
                color={value > 0 ? 'primary' : 'successDark'}
                weight="bold-700"
              >
                {dollars}
                <Layout.Column justify="flex-end">
                  <OpenSans.Primary
                    size="xs-12"
                    color={value > 0 ? 'primary' : 'successDark'}
                    weight="bold-700"
                  >
                    .{cents}
                  </OpenSans.Primary>
                  <Spacer.Vertical size={1.5} />
                </Layout.Column>
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorKey: 'receipts',
        header: getTranslation('Receipt'),
        muiTableHeadCellProps: {
          align: 'right',
        },
        Header: () => (
          <OpenSans.Secondary size={'s-12'} style={{ paddingLeft: 6 }}>
            {getTranslation('Receipt')}
          </OpenSans.Secondary>
        ),

        Filter: (props) => {
          return (
            <TextField
              fullWidth
              margin="none"
              onChange={(e) => props.column.setFilterValue(e.target.value || undefined)}
              placeholder="Filter"
              select
              value={props.column.getFilterValue() ?? ''}
              variant="standard"
            >
              <MenuItem value="none">All</MenuItem>
              <MenuItem value="true">Has Receipt</MenuItem>
              <MenuItem value="false">No Receipt</MenuItem>
            </TextField>
          );
        },
        filterFn: (row, id, filterValue) => {
          if (filterValue === 'none') {
            return true;
          }

          const isTrueSet = filterValue === 'true';
          return row.original.receipts.length > 0 === isTrueSet;
        },
        enableSorting: false,
        size: 10,
        Cell: ({ row }) => {
          const receiptURL = row.original.receipts[0];
          return (
            <Layout.Column align="flex-end">
              <ReceiptButton
                receipt={row.original.receipts[0]}
                setModalOpen={() => onReceiptButtonClick(receiptURL)}
              />
            </Layout.Column>
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: expensesWithData,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: [{ desc: true, id: 'date' }],
      pagination: { pageIndex: 0, pageSize: 25 },
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
      FilterAltIcon: () => <Icon name="funnel" size={18} color={theme.colors.secondary} />,
      ViewColumnIcon: () => <Icon name="build-outline" size={18} color={theme.colors.secondary} />,
    },
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.key,
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.id),
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />
          <Spacer.Horizontal size={6} />
          <MRT_ShowHideColumnsButton table={table} />
          <Spacer.Horizontal size={6} />

          <MRT_ToggleFiltersButton table={table} />
        </Layout.Row>
      );
    },
  });

  return (
    <>
      <ReceiptSideModal
        modalOpen={showModal}
        setModalState={setShowModal}
        receiptURI={receiptURI}
      />
      <Layout.Column>
        <Layout.Row>{header}</Layout.Row>
        <MaterialReactTable table={table} />
      </Layout.Column>
    </>
  );
};
