import { getTranslation } from '@easy-expense/intl-client';
import { Layout, Spacer, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';

import { ExportDataType } from './ExportDataType.component';
import { ExportDateFilter } from './ExportDateFilter.component';
import { ExportExpenseFilters } from './ExportExpenseFilters.component';
import { ExportFileType } from './ExportFileType.component';
import { ExportFilter } from './ExportFilters.component';
import { ExportHeader } from './ExportHeader.component';
import { ExportSettingComponent } from './ExportSettings.component';
import { DataExport, DataType, DownloadType, ExportFilters, ExportSettings } from './types';
import { useExportData, useExportPDFData } from '../../hooks/downloads/useDownloadReport';
import { Button } from '../Button.components';
import LoadingSpinner from '../LoadingSpinner.component';

export const ExportContent: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const thisYear = new Date().getFullYear().toString();

  const [dataExport, setDataExport] = React.useState<DataExport>({
    dataType: DataType.transactions,
    downloadType: DownloadType.excel,
    startDate: `${thisYear}-01-01`,
    endDate: undefined,
    settings: {
      includeArchived: false,
      downloadReceipts: false,
    },
  });

  const { submitDownloadRequest, error, sharedResource } = useExportData(dataExport);

  const { submitPDFDownloadRequest, filteredPDFRequest } = useExportPDFData(dataExport);

  function downloadExport() {
    setLoading(true);
    if (dataExport.downloadType === 'pdf') {
      submitPDFDownloadRequest();
    } else {
      submitDownloadRequest();
    }
  }

  React.useEffect(() => {
    directDownloadLink(filteredPDFRequest?.shareURL);
  }, [filteredPDFRequest]);

  React.useEffect(() => {
    directDownloadLink();
  }, [sharedResource]);

  React.useEffect(() => {
    //TODO: Alert error: https://linear.app/easy-expense/issue/EE-3452/alert-errors-on-export-failure
    setLoading(false);
  }, [error]);

  function directDownloadLink(url?: string | null) {
    const thelink = url ?? sharedResource?.shareURL;
    if (thelink) {
      const link = document.createElement('a');
      link.href = thelink;
      link.download = thelink || 'downloaded-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(thelink);

      setLoading(false);
    }
  }

  return (
    <Layout.Column
      py={24}
      px={32}
      grow
      style={{ height: '100%', width: '100%', overflow: 'scroll' }}
    >
      {loading ? (
        <Layout.Column
          style={{
            width: '100vh',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            justifyContent: 'center',
            position: 'absolute',
            zIndex: zIndex.LoadingOverlay,
          }}
        >
          <LoadingSpinner />
        </Layout.Column>
      ) : null}

      <Layout.Row style={{ minWidth: 1000 }}>
        <Spacer.Flex />
        <Layout.Column style={{ width: '100%' }}>
          <ExportHeader onDownload={() => downloadExport()} />

          <ExportFileType
            downloadType={dataExport.downloadType}
            setDownloadType={(downloadType: DownloadType) => {
              setDataExport({ ...dataExport, downloadType });
            }}
          />
          <ExportDataType
            dataType={dataExport.dataType}
            setDataType={(dataType: DataType) => {
              setDataExport({ ...dataExport, dataType });
            }}
          />

          <ExportDateFilter
            dateFilters={{
              startDate: dataExport.startDate,
              endDate: dataExport.endDate,
            }}
            setDateFilters={(dateFilters: { startDate: string; endDate?: string }) => {
              setDataExport({
                ...dataExport,
                startDate: dateFilters.startDate,
                endDate: dateFilters.endDate,
              });
            }}
          />

          <ExportFilter
            exportFilters={{
              createdByKey: dataExport.createByKey,
            }}
            setExportFilters={(exportFilters: ExportFilters) => {
              setDataExport({
                ...dataExport,

                createByKey: exportFilters.createdByKey,
              });
            }}
          />

          {dataExport.dataType === DataType.transactions ? (
            <ExportExpenseFilters
              exportFilters={{
                vendorKey: dataExport.vendorKey,
                categoryKey: dataExport.categoryKey,
                paymentMethodKey: dataExport.paymentMethodKey,
              }}
              setExportFilters={(exportFilters: ExportFilters) => {
                setDataExport({
                  ...dataExport,
                  vendorKey: exportFilters.vendorKey,
                  categoryKey: exportFilters.categoryKey,
                  paymentMethodKey: exportFilters.paymentMethodKey,
                });
              }}
            />
          ) : null}

          <ExportSettingComponent
            setSettings={(settings: ExportSettings) => {
              setDataExport({
                ...dataExport,
                settings,
              });
            }}
            settings={dataExport.settings}
          />
          <Layout.Row align>
            <Spacer.Vertical size={16} />

            <Button.Primary
              px
              py
              radius
              center
              content={getTranslation('Download')}
              onClick={() => downloadExport()}
            />
          </Layout.Row>
        </Layout.Column>
        <Spacer.Flex />
      </Layout.Row>
    </Layout.Column>
  );
};
