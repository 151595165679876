import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image, Linking, Pressable } from 'react-native';

import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { useIsMobile, useWindowDimensions } from '../../hooks/useWindow';

const StepRow: React.FC<{ stepNumber: string; stepText: string }> = ({ stepNumber, stepText }) => {
  return (
    <Layout.Row align style={{ width: '100%' }}>
      <Layout.Column
        bg="grayXLight"
        style={{
          width: 42,
          height: 42,
        }}
        align
        center
        radius={42}
      >
        <OpenSans.Primary weight="bold-700" size={24}>
          {stepNumber}
        </OpenSans.Primary>
      </Layout.Column>

      <Spacer.Horizontal size={16} />

      <OpenSans.Primary grow>{stepText}</OpenSans.Primary>
    </Layout.Row>
  );
};

export const ReceiptReportShareImage: React.FC = () => {
  return (
    <Layout.Column>
      <Image
        source={require('../../../assets/receipt-report-share.svg')}
        style={{ height: 500, width: 430 }}
      />
    </Layout.Column>
  );
};

const SignUpSteps: React.FC = () => {
  return (
    <Layout.Column>
      <OnboardingActionColumn width={430}>
        <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
          Get Started
        </OpenSans.Primary>
        <Spacer.Vertical size={24} />
        <StepRow
          stepNumber="1"
          stepText="Use your phone's camera to scan the QR code to download the app."
        />
        <Spacer.Vertical size={24} />
        <Image source={require('../../../assets/QRCODE.png')} style={{ height: 200, width: 200 }} />
        <Spacer.Vertical size={24} />
        <StepRow stepNumber="2" stepText="Login with your email and password." />
        <Spacer.Vertical size={24} />
        <StepRow stepNumber="3" stepText="Start scanning your receipts." />
        {/* TODO: Activate phone input thing */}
        {/* <Layout.Column
          style={{
            borderTop: '1px solid #9B9B9B',
            margin: '20px 0',
            width: '100%',
          }}
          align
        >
          <OpenSans.Primary style={{ color: '#9B9B9B' }}>
            OR GET A TEXT WITH A LINK
          </OpenSans.Primary>
        </Layout.Column>
        <Layout.Column style={{ width: '100%' }}>
          <LabelTextField
            label=""
            styles={{ backgroundColor: theme.colors.inputBackground, width: '100%' }}
          >
            <Layout.Row align style={{ width: '100%' }}>
              <Icon name="storefront-outline" size={20} />

              <Spacer.Horizontal size={10} />
              <OpenSans.Input
                name="phoneNumber"
                value={phoneNumber}
                type="text"
                min="0"
                step="0.01"
                weight="light-300"
                placeholder="Phone number"
                grow
                style={{ outline: 'none' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPhoneNumber(event.target.value)
                }
                autoComplete="off"
              />
            </Layout.Row>
          </LabelTextField>
        </Layout.Column>
        <Spacer.Vertical size={12} />
        <Layout.Column style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Button.Primary
            px
            py
            radius
            center
            style={{ width: '100%' }}
            content={'Send me a download link'}
          />
        </Layout.Column> */}
      </OnboardingActionColumn>
    </Layout.Column>
  );
};

const MobileSignUpSteps: React.FC = () => {
  return (
    <Layout.Column center px>
      <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
        Get Started
      </OpenSans.Primary>
      <Spacer.Vertical size={18} />
      <StepRow stepNumber="1" stepText="Install the app." />

      <Spacer.Vertical size={12} />
      <StepRow stepNumber="2" stepText="Login with your email and password." />
      <Spacer.Vertical size={12} />
      <StepRow stepNumber="3" stepText="Start scanning your receipts." />
      <Spacer.Vertical size={32} />
      <Layout.Row grow center style={{ width: '100%' }}>
        <Layout.Column>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://apps.apple.com/app/apple-store/id1528787066?pt=122013942&amp;ct=marketingweb&amp;mt=8',
              )
            }
          >
            <Image
              source={require('../../images/icons/app-store-badge.svg')}
              style={{ width: 135, height: 40 }}
              accessibilityLabel="App Store"
            />
          </Pressable>
        </Layout.Column>
        <Spacer.Horizontal size={32} />
        <Layout.Column>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://play.google.com/store/apps/details?id=com.easyexpense&amp;utm_source=marketing&amp;utm_medium=web',
              )
            }
          >
            <Image
              accessibilityLabel="Google Play"
              source={require('../../images/icons/google-play-badge.svg')}
              style={{ width: 135, height: 40 }}
            />
          </Pressable>
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};

export const GetStarted: React.FC<React.PropsWithChildren<object>> = () => {
  const { width } = useWindowDimensions();
  const isMobile = useIsMobile();
  return (
    <OnboardingBackdrop>
      {width < 900 ? null : <ReceiptReportShareImage />}
      {isMobile ? <MobileSignUpSteps /> : <SignUpSteps />}
    </OnboardingBackdrop>
  );
};
