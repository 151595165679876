import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TeamSetup } from '../../components/Onboarding/TeamSetup.component';
import { TeamPlanCode } from '../../types';

export const TeamSetupPage: React.FC = () => {
  const { planCode } = useParams<{ planCode: TeamPlanCode }>();
  const navigate = useNavigate();

  if (!planCode) {
    navigate('/404');
    return;
  }

  return <TeamSetup planCode={planCode} />;
};
