import { EasyExpenseIssuing } from '@easy-expense/utils-shared';
import React from 'react';

import { AddNewCardholder } from './AddNewCardholder.component';
import { AddNewModalProps } from './types';

export const AddNewSwitch = React.memo<
  { entity: keyof EasyExpenseIssuing.ClientAPI.GetMethods } & AddNewModalProps
>(({ entity, modalOpen, setModalOpen, accountID }) => {
  switch (entity) {
    case 'cardholders': {
      return (
        <AddNewCardholder modalOpen={modalOpen} setModalOpen={setModalOpen} accountID={accountID} />
      );
    }
    default:
      return null;
  }
});
