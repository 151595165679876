import { InvoiceStatus, PaymentLink } from '@easy-expense/data-schema-v2';
import { WebEnv } from '@easy-expense/env-mobile';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

import { InvoiceHeader } from '../components/Invoice/InvoiceHeader.component';
import { StripePaymentForm } from '../components/Invoice/StripePaymentForm.component';
import LoadingSpinner from '../components/LoadingSpinner.component';

export const Invoice: React.FC = () => {
  const { paymentID, accountID } = useParams();
  const [invoice, setInvoice] = React.useState<Partial<PaymentLink>>();
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setLoading] = React.useState(true);

  const getPaymentLinkURL = () => {
    const ENDPOINT = '/stripe-stripeEndpoint/payment-link';
    const url = new URL(window.location.href);
    const baseDomain = url.hostname.split('.').slice(-2).join('.');
    if (baseDomain === 'localhost') {
      return `http://127.0.0.1:5001/easy-expense-dev/us-central1${ENDPOINT}`;
    }
    return `${WebEnv.firebase.cloudPrefix}/${ENDPOINT}`;
  };

  React.useEffect(() => {
    const getPaymentLink = async () => {
      try {
        const response = await axios.get(getPaymentLinkURL(), {
          params: {
            accountID,
            paymentID,
          },
        });
        switch (response.data?.status) {
          case InvoiceStatus.Enum.Pending: {
            setInvoice(response.data);
            break;
          }
          case InvoiceStatus.Enum.Paid: {
            setErrorMessage('This invoice has already been paid');
            break;
          }
          default: {
            setErrorMessage('This invoice is no longer valid');
            break;
          }
        }
      } catch (error) {
        setErrorMessage("This invoice doesn't exist");
      } finally {
        setLoading(false);
      }
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    if (accountID && paymentID) {
      getPaymentLink();
    }
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return (
      <Layout.Column grow align center>
        <LoadingSpinner />
      </Layout.Column>
    );
  }

  return (
    <Layout.Row grow>
      {invoice ? (
        <>
          {isSmallScreen ? null : (
            <Layout.Column px={'3xl-38'} style={{ minWidth: isSmallScreen ? '100%' : '50%' }}>
              <Spacer.Vertical size={75} />
              <InvoiceHeader invoice={invoice} />
            </Layout.Column>
          )}

          <Layout.Column
            py
            px={'3xl-38'}
            grow
            style={{ minHeight: '100%', minWidth: '50%' }}
            align="center"
            shadow
          >
            {isSmallScreen ? <InvoiceHeader invoice={invoice} /> : null}
            <StripePaymentForm invoice={invoice} />
          </Layout.Column>
        </>
      ) : (
        <Layout.Column align center grow>
          <OpenSans.Primary weight="bold-700" size="l-20">
            {errorMessage}
          </OpenSans.Primary>
        </Layout.Column>
      )}
    </Layout.Row>
  );
};
