import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { OnboardingActionColumn } from './OnboardingBackdrop.component';
import LoadingSpinner from '../LoadingSpinner.component';

export const WorkspaceLoader: React.FC = () => {
  return (
    <OnboardingActionColumn>
      <OpenSans.Primary center weight="bold-700" size={36}>
        {getTranslation('Setting up your workspace')}
      </OpenSans.Primary>
      <Spacer.Vertical size={16} />
      <OpenSans.Primary center size={26}>
        {getTranslation('This should only take a second')}
      </OpenSans.Primary>
      <Spacer.Vertical size={16} />
      <LoadingSpinner size={32} />
    </OnboardingActionColumn>
  );
};
