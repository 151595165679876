import { css, styled } from '@easy-expense/ui-theme';
import { Layout } from '@easy-expense/ui-web-core';

export type CircleProps = { circleSize: number };

export const baseCircleStyle = css<CircleProps>`
  border-radius: ${({ circleSize }: { circleSize: number }) => circleSize / 2}px;
  width: ${({ circleSize }: { circleSize: number }) => circleSize}px;
  height: ${({ circleSize }: { circleSize: number }) => circleSize}px;
`;

const CircleSolid = styled(Layout.Column)<CircleProps>`
  ${baseCircleStyle}
`;

/**
 * Useful for any circle shaped UI elements, usually
 * visual / has a background
 */
export const Circle = {
  Solid: CircleSolid,
};
