import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

import { AutoCompleteValue } from './AutoCompleteTypes';
import { Circle } from '../../Shape.components';

export const InputIcon: React.FC<{
  autoCompleteValue: AutoCompleteValue;
}> = ({ autoCompleteValue }) => {
  const icon = autoCompleteValue.value;

  if (icon.image) {
    return <img style={{ borderRadius: 50, height: 20, width: 20 }} src={icon.image} />;
  }
  if (icon.iconicIcon) {
    return <Icon name={icon.iconicIcon} size={20} />;
  }
  if (icon.icon) {
    return (
      <OpenSans.Primary size={16} weight="bold-700">
        {icon.icon}
      </OpenSans.Primary>
    );
  }
  return (
    <Circle.Solid
      circleSize={20}
      style={{ backgroundColor: theme.colors.brandPrimaryLight }}
      center
    >
      <OpenSans.Primary size={12} weight="bold-700">
        {autoCompleteValue.value.name.charAt(0)}
      </OpenSans.Primary>
    </Circle.Solid>
  );
};
