import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { DistanceUnit } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

const DistanceUnits = [
  {
    key: 'Mile',
    value: {
      name: 'Mile',
      iconicIcon: 'car-outline' as IconName,
    },
  },
  {
    key: 'Kilometer',
    value: {
      name: 'Kilometer',
      iconicIcon: 'car-outline' as IconName,
    },
  },
];

export const WorkspaceDistanceUnit: React.FC = () => {
  const workspace = useCurrentWorkspace();

  const distanceUnits = DistanceUnits;

  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  function onChange(distanceUnit?: string) {
    if (distanceUnit) {
      updateWorkspace({
        settings: {
          ...workspace.settings,
          distanceUnit: distanceUnit as DistanceUnit,
        },
      });
    }
  }
  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column
        style={{
          maxWidth: 350,
        }}
      >
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Distance Unit')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16} weight="bold-700">
            {getTranslation('Do you want your trips to be recorded in kilometers or miles')}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <SearchDropdown
          header={''}
          placeholder={getTranslation('Distance Dropdown')}
          selectedKey={workspace.settings.distanceUnit}
          autoCompleteValues={distanceUnits}
          onSelect={(c) => {
            onChange(c?.key);
          }}
          error={false}
          iconicIcon="car-outline"
        />
      </Layout.Column>
    </Layout.Row>
  );
};
