import React from 'react';

import { AutoCompleteInputParams } from './AutoCompleteTypes';
import { BaseAutoCompleteInput } from './BaseAutoCompleteDropdown.component';

export const InsertDropdown: React.FC<React.PropsWithChildren<AutoCompleteInputParams>> = (
  params,
) => {
  const initialKey = params.selectedKey;
  const initialValue = params.autoCompleteValues.find((value) => value.key === initialKey) || null;

  function onInputFocus() {}

  function onInputBlur(curValue: string) {
    const exists = params.autoCompleteValues.find(
      (entity) => curValue && entity.value.name === curValue,
    );
    if (!exists) {
      params.onSelect({ value: { name: curValue } });
    } else {
      params.onSelect(exists);
    }
  }
  return (
    <BaseAutoCompleteInput
      {...params}
      allowCreateNew={true}
      onInputBlur={onInputBlur}
      onInputFocus={onInputFocus}
      value={initialValue}
    />
  );
};
