import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import {
  AddressElement,
  CardElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import React from 'react';

import { Button } from '../Button.components';

export const CheckoutForm: React.FC<React.PropsWithChildren<{ secret: string }>> = ({ secret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = React.useState(false);

  const options: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    await elements.submit();

    if (elements) {
      const paymentIntent = await stripe.confirmPayment({
        elements,
        clientSecret: secret,
        confirmParams: { return_url: '' },
        redirect: 'if_required',
      });

      if (paymentIntent.error) {
        console.log('This is an error', paymentIntent.error);
        if (paymentIntent.error.type === 'card_error') {
          alert('There was an error processing your payment');
        }
      } else {
        console.log('This is a successful payment', paymentIntent);
        setSuccess(true);
      }
    }
  };

  return (
    <Layout.Column px py grow>
      {!success ? (
        <>
          <LinkAuthenticationElement options={{}} />
          <Spacer.Vertical size={32} />
          {/* @ts-ignore stripe's components do not explicitly define children prop in their types as is required by React 18 */}
          <PaymentElement options={options}>
            <CardElement />
          </PaymentElement>
          <Spacer.Vertical size={32} />
          <AddressElement options={{ mode: 'shipping' }} />
          <Spacer.Vertical size={32} />
          <Button.Primary variant="bold" content="Pay" onClick={handlePayment} />
        </>
      ) : (
        <>
          <OpenSans.Primary>Great Success!</OpenSans.Primary>
        </>
      )}
    </Layout.Column>
  );
};
