import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

export function useFreshchat() {
  return React.useEffect(() => {
    function initFreshChat() {
      (window as DoNotFixMeIAmAny).fcWidget.init({
        token: '5cf30597-2420-48d0-843a-9e4b473a8ba2',
        host: 'https://easyexpense-team.freshchat.com',
      });
    }

    function initialize(i: DoNotFixMeIAmAny, t: DoNotFixMeIAmAny) {
      let e;
      i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://easyexpense-team.freshchat.com/js/widget.js'),
          (e.onload = initFreshChat),
          i.head.appendChild(e));
    }
    function initiateCall() {
      initialize(document, 'Freshchat-js-sdk');
    }

    window.addEventListener('load', initiateCall, !1);
  }, []);
}
