import { useIssuingStore } from '@easy-expense/data-firestore-client';
import { Layout, OpenSans, Separator, Spacer } from '@easy-expense/ui-web-core';
import { StripeErrorCode, Wrapped } from '@easy-expense/utils-shared';
import React from 'react';
import { Stripe } from 'stripe';

export const AccountDashboard: React.FC<{
  account: Stripe.Response<Wrapped.Account>;
}> = ({ account }) => {
  const hasRequirements = React.useMemo(
    () =>
      account?.requirements?.currently_due?.length || account?.requirements?.eventually_due?.length,
    [account],
  );
  const onPressFinish = React.useCallback(() => {
    useIssuingStore
      .getState()
      .refreshEntity('onboarding', {
        platform: 'web',
      })
      .then((url: string | void | undefined) => {
        if (url) {
          window.location.href = url;
        }
      })
      .catch((e) => {
        alert(e in StripeErrorCode ? e : StripeErrorCode.APIError);
      });
  }, []);

  return (
    <Layout.Column grow>
      <Layout.Row>
        <Layout.Column grow>
          <Layout.Row grow px py>
            <OpenSans.Primary weight="bold-700">
              {account.business_profile?.name ?? 'Unnamed'}
            </OpenSans.Primary>
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      </Layout.Row>

      <Layout.Row>
        <Layout.Column grow>
          <Layout.Row grow px py align>
            <OpenSans.Pressable onClick={onPressFinish} style={{ cursor: 'pointer' }}>
              {hasRequirements ? 'Finish Account Setup' : 'Manage Account'}
            </OpenSans.Pressable>
            {hasRequirements ? (
              <>
                <Spacer.Horizontal />
                <Layout.Column bg="destructiveLight" radius px py={4}>
                  <OpenSans.Primary color="destructive">Due Now</OpenSans.Primary>
                </Layout.Column>
              </>
            ) : null}
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
