import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

export const AccountSettings: React.FC = () => {
  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <OpenSans.Primary> {getTranslation('Account settings coming soon')}</OpenSans.Primary>
    </Layout.Column>
  );
};
