import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image } from 'react-native';

import { SideModal } from '../SideModal.component';

export const ReceiptSideModal: React.FC<
  React.PropsWithChildren<{
    modalOpen: boolean;
    setModalState: (bool: boolean) => void;
    receiptURI: string;
  }>
> = ({ modalOpen, setModalState, receiptURI }) => {
  return (
    <SideModal
      modalOpen={modalOpen}
      setModalOpen={() => {
        setModalState(false);
      }}
    >
      <Layout.Column bg="grayXLight" style={{ width: '100%', height: '100%' }} px py>
        <Layout.Column grow>
          <Image
            source={{ uri: receiptURI }}
            style={{ height: '100%', width: '100%' }}
            resizeMode="contain"
          />
        </Layout.Column>
        <Spacer.Vertical size={12} />
        <Layout.Row center>
          <Layout.PressableRow
            onClick={() => {
              setModalState(false);
            }}
            py
            center
            bg={'brandPrimary'}
            border={[0, 'solid', 'gray']}
            radius={4}
            style={{ width: 250 }}
          >
            <OpenSans.Inverse weight="bold-700">Close</OpenSans.Inverse>
          </Layout.PressableRow>
        </Layout.Row>
      </Layout.Column>
    </SideModal>
  );
};
