import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

export const ReceiptButton: React.FC<{
  setModalOpen: (bool: boolean) => void;
  receipt?: string;
}> = ({ receipt, setModalOpen }) => {
  const [hover, setHover] = React.useState(false);

  if (receipt) {
    return (
      <Layout.PressableRow
        px={6}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setModalOpen(true);
        }}
        style={{ background: 'none' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Icon name={hover ? 'receipt' : 'receipt-outline'} size={12} color={theme.colors.primary} />
      </Layout.PressableRow>
    );
  }

  return (
    <Layout.Row
      px={6}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        setModalOpen(true);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      border={[1, 'solid', 'inputBorder']}
      radius={100}
      py={6}
      bg="inputBackground"
    >
      <Icon name={'add'} size={12} iconColor="primary" />
    </Layout.Row>
  );
};
