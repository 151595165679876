import { useIsAdminRole } from '@easy-expense/data-firestore-client';
import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

import { AccountSettings } from './AccountSettings.component';
import { SettingsHeader, SettingsView } from './SettingsHeader.component';
import { WorkspaceSettings } from './WorkspaceSettings/WorkspaceSettings.component';
import { CategoriesTable } from '../Categories/CategoriesTable.component';

export const SettingsContent: React.FC = () => {
  const isAdmin = useIsAdminRole();
  const [settingsView, setSettingsView] = React.useState<SettingsView>(
    isAdmin ? SettingsView.workspace : SettingsView.account,
  );

  const SettingsDisplay = () => {
    if (settingsView === SettingsView.workspace) {
      return <WorkspaceSettings />;
    }
    if (settingsView === SettingsView.account) {
      return <AccountSettings />;
    }
    if (settingsView === SettingsView.categories) {
      return <CategoriesTable />;
    }
    return null;
  };
  return (
    <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
      <Layout.Column style={{ minWidth: 600, width: '100%' }}>
        <SettingsHeader settingsView={settingsView} setSettingsView={setSettingsView} />
        <SettingsDisplay />
      </Layout.Column>
    </Layout.Column>
  );
};
