import { Layout, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image } from 'react-native';

import { useIsMobile } from '../../hooks/useWindow';

export const OnboardingActionColumn: React.FC<React.PropsWithChildren<{ width?: number }>> = ({
  children,
  width,
}) => {
  const isMobile = useIsMobile();

  return (
    <Layout.Column
      align
      shadow={isMobile ? undefined : true}
      px={isMobile ? 16 : 52}
      py={isMobile ? 16 : 32}
      radius={20}
      border={isMobile ? undefined : [1, 'solid', 'grayLight']}
      style={{
        width: '100%',
        maxWidth: width ? width : 600,
      }}
      bg="white"
    >
      {children}
    </Layout.Column>
  );
};

export const OnboardingBackdrop: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <Layout.Column
      style={{ height: '100%', width: '100%' }}
      bg={isMobile ? 'white' : 'brandPrimaryXLight'}
      debug
    >
      <Layout.Row px={24} py={12}>
        <Layout.PressableColumn
          onClick={() => {
            window.location.replace('https://easy-expense.com');
          }}
          bg={isMobile ? 'white' : 'brandPrimaryXLight'}
        >
          <Image
            source={require('../../images/icons/logo.png')}
            style={{ width: 135, height: 48 }}
          />
        </Layout.PressableColumn>
      </Layout.Row>
      <Layout.Row center align grow style={{ width: '100%' }} justify="space-evenly">
        {children}
      </Layout.Row>
      <Spacer.Vertical size={36} />
    </Layout.Column>
  );
};
