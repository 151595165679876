import Bugsnag from '@bugsnag/js';
import { Analytics } from '@easy-expense/analytics-client';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, Navigate } from 'react-router-dom';

import { auth } from '../firebase/app';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  React.useEffect(() => {
    if (user) {
      Bugsnag.setUser(user.uid, user.email ?? undefined);
      Analytics.identify(user.uid, { email: user.email, ...user.metadata });
    }
  }, [user]);

  if (!user && !loading) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  return children;
};
