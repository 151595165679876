import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer, OpenSans } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

export const LabelTextField: React.FC<
  React.PropsWithChildren<{
    label: string;
    active?: boolean;
    error?: boolean;
    errorMessage?: string;
    styles?: DoNotFixMeIAmAny;
    disabled?: boolean;
  }>
> = (props) => {
  const { label, children, active, error, errorMessage, styles, disabled } = props;

  let labelColor = 'secondary';
  if (error) {
    labelColor = 'destructive';
  } else if (active) {
    labelColor = 'brandPrimary';
  }

  let borderColor = 'inputBorder';
  let borderWidth = 1;
  if (disabled) {
    borderColor = 'gray';
    borderWidth = 1;
  } else if (active) {
    borderColor = 'brandPrimary';
    borderWidth = 1;
  } else if (error) {
    borderColor = 'destructive';
    borderWidth = 2;
  }

  let backgroundColor = 'inputBackground';
  if (disabled) {
    backgroundColor = 'grayXLight';
  } else if (error && !active) {
    backgroundColor = 'destructiveXLight';
  }

  return (
    <Layout.Column>
      <OpenSans.Custom size="xs-12" color={labelColor} weight="bold-700">
        {label}
      </OpenSans.Custom>
      <Spacer.Vertical size={6} />
      <Layout.Column>
        <Layout.Column
          border={[borderWidth, 'solid', borderColor]}
          radius={4}
          px
          py={4}
          style={{
            minHeight: 40,
            borderBottomWidth: active ? 3 : undefined,
            borderBottomColor: active ? theme.colors.brandPrimary : undefined,
            ...styles,
          }}
          bg={backgroundColor}
          justify
        >
          {children}
        </Layout.Column>
      </Layout.Column>
      {error && !errorMessage ? (
        <Layout.Column>
          <Spacer.Vertical size={4} />
          <Layout.Row align px>
            <Icon name="warning" color="red" size={16} />
            <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
              {getTranslation('This field is required')}
            </OpenSans.Custom>
          </Layout.Row>
        </Layout.Column>
      ) : null}
      {errorMessage ? (
        <Layout.Column>
          <Spacer.Vertical size={4} />
          <Layout.Row align px>
            <Icon name="warning" color="red" size={16} />
            <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
              {errorMessage}
            </OpenSans.Custom>
          </Layout.Row>
        </Layout.Column>
      ) : null}
    </Layout.Column>
  );
};
