import { useEffect, useRef } from 'react';

export const useMounted = () => {
  const mounted = useRef(true);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );
  return mounted;
};

// TODO should be a shared hook @wmatous @stephenlaughton
