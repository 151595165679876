import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { CURRENCIES, getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

export const WorkspaceCurrencySetting: React.FC = () => {
  const currencies = CURRENCIES;

  const currencySet = React.useMemo(() => {
    const set = Object.values(currencies);
    return set.map((c) => {
      return { key: c.code, value: { name: c.code, iconicIcon: 'cash-outline' as IconName } };
    });
  }, [currencies]);

  const workspace = useCurrentWorkspace();

  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  function onChange(code?: string) {
    if (code) {
      updateWorkspace({
        settings: {
          ...workspace.settings,
          currencyCode: code,
        },
      });
    }
  }

  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column style={{ maxWidth: 350 }}>
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Currency')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16} weight="bold-700">
            {getTranslation(
              'All transactions will automatically be converted into your workspaces currency',
            )}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <SearchDropdown
          header={''}
          placeholder={getTranslation('Currency Selector')}
          selectedKey={workspace.settings.currencyCode}
          autoCompleteValues={[...currencySet]}
          onSelect={(c) => {
            onChange(c?.key);
          }}
          error={false}
          iconicIcon="cash-outline"
        />
      </Layout.Column>
    </Layout.Row>
  );
};
