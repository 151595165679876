export enum DownloadType {
  excel = 'excel',
  pdf = 'pdf',
}
export enum DataType {
  transactions = 'transactions',
  trips = 'trips',
}

export type ExportSettings = {
  downloadReceipts?: boolean;
  includeArchived?: boolean;
};

export type DataExport = {
  downloadType: DownloadType;
  dataType: DataType;
  startDate: string;
  endDate?: string;
  vendorKey?: string;
  categoryKey?: string;
  createByKey?: string;
  paymentMethodKey?: string;
  settings: ExportSettings;
};

//TODO: Make export filters multi selects
export type ExportFilters = {
  vendorKey?: string;
  categoryKey?: string;
  createdByKey?: string;
  paymentMethodKey?: string;
};
