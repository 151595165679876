import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

export const ExpenseSearch: React.FC<{
  search: string | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ search, setSearch }) => (
  <Layout.Column style={{ width: '100%' }}>
    <Layout.Row
      bg="inputBackground"
      radius
      grow
      border={[1, 'solid', 'inputBorder']}
      align
      px
      py={6}
    >
      <Icon name="search-outline" size={20} color={theme.colors.secondary} />
      <Spacer.Horizontal size={8} />
      <OpenSans.Input
        grow
        value={search}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        placeholder="Search"
        style={{ outline: 'none' }}
      />
    </Layout.Row>
    <Spacer.Vertical size={12} />
  </Layout.Column>
);
