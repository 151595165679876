import { LayoutProps } from '@easy-expense/ui-theme';
import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

export const CardContainer: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  ...layout
}) => {
  return (
    <Layout.Column
      radius
      bg="navHeaderBackground"
      style={{ overflow: 'hidden' }}
      border={[1, 'solid', 'grayLight']}
      {...layout}
    >
      {children}
    </Layout.Column>
  );
};
