import { theme } from '@easy-expense/ui-theme';
import { OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../LabelTextField.component';

export const ReportName: React.FC<
  React.PropsWithChildren<{
    onChange: (name: string) => void;
    name?: string;
  }>
> = ({ name = '', onChange }) => {
  return (
    <LabelTextField label="Report Name" styles={{ backgroundColor: theme.colors.inputBackground }}>
      <OpenSans.Input
        name="report"
        value={name}
        type="text"
        weight="bold-700"
        placeholder="Report Name"
        grow
        size={28}
        style={{ outline: 'none', width: '80%' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        autoComplete="off"
      />
    </LabelTextField>
  );
};
