import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Image } from 'react-native';

import { DownloadType } from './types';

export const ExportFileType: React.FC<{
  downloadType: DownloadType;
  setDownloadType: (type: DownloadType) => void;
}> = ({ downloadType, setDownloadType }) => {
  return (
    <Layout.Column>
      <OpenSans.Secondary size="xs-12" color={'primary'} weight="bold-700">
        {getTranslation('File Type')}
      </OpenSans.Secondary>
      <Spacer.Vertical size={12} />
      <Layout.Row align>
        <Layout.PressableRow
          onClick={() => setDownloadType(DownloadType.excel)}
          bg={downloadType === DownloadType.excel ? 'primary' : 'inputBackground'}
          border={
            downloadType === DownloadType.excel
              ? [1, 'solid', 'primary']
              : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Image
            source={require('../../images/assets/excel_image.png')}
            style={{
              height: 24,
              width: 24,
            }}
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary color={downloadType === DownloadType.excel ? 'white' : 'primary'}>
            {getTranslation('Excel/ CSV')}
          </OpenSans.Primary>
        </Layout.PressableRow>

        <Spacer.Horizontal />
        <Layout.PressableRow
          onClick={() => setDownloadType(DownloadType.pdf)}
          bg={downloadType === DownloadType.pdf ? 'primary' : 'inputBackground'}
          border={
            downloadType === DownloadType.pdf
              ? [1, 'solid', 'primary']
              : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Icon
            name="document-attach-outline"
            size={16}
            color={downloadType === DownloadType.pdf ? theme.colors.white : theme.colors.primary}
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary color={downloadType === DownloadType.pdf ? 'white' : 'primary'}>
            {getTranslation('PDF Report')}
          </OpenSans.Primary>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
