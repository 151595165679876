import React, { CSSProperties } from 'react';
import { ClipLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
};

const LoadingSpinner: React.FC<React.PropsWithChildren<{ size?: number }>> = ({ size = 100 }) => {
  const [color] = React.useState('#000');
  return <ClipLoader color={color} loading={true} cssOverride={override} size={size} />;
};

export default LoadingSpinner;
