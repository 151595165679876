import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { AddNewCategory } from './AddNewCategory.component';
import { Modal } from '../Shared/Modal.component';

export const CategoriesHeader: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const [showAddCategoryModal, setShowAddCategoryModal] = React.useState(false);

  function onAddNewCategory() {
    setShowAddCategoryModal(false);
  }

  return (
    <Layout.Row align>
      <OpenSans.Primary size={32} weight="bold-700">
        {getTranslation('Categories')}
      </OpenSans.Primary>
      <Spacer.Horizontal size={32} />
      <Layout.PressableRow
        onClick={() => setShowAddCategoryModal(true)}
        bg="inputBackground"
        border={[1, 'solid', 'inputBorder']}
        py={4}
        px
        radius={100}
        align
      >
        <Icon name="add-outline" size={16} color={theme.colors.primary} />
        <Spacer.Horizontal size={8} />
        <OpenSans.Primary>{getTranslation('Add Category')}</OpenSans.Primary>
      </Layout.PressableRow>
      <Modal showModal={showAddCategoryModal}>
        <AddNewCategory
          onAddNewCategory={onAddNewCategory}
          onDiscard={() => setShowAddCategoryModal(false)}
        />
      </Modal>
    </Layout.Row>
  );
};
