import { useIntlStore } from '@easy-expense/intl-client';
import { Layout, OpenSans, Separator } from '@easy-expense/ui-web-core';
import { StripeToEEParse, Wrapped } from '@easy-expense/utils-shared';
import React from 'react';
import { SectionList } from 'react-native';
import { Stripe } from 'stripe';

export const BalanceDashboard: React.FC<{
  balance: Stripe.Response<Wrapped.Balance>;
}> = ({ balance }) => {
  const { formatCurrency } = useIntlStore();
  return (
    <SectionList
      renderSectionHeader={({ section }) => (
        <OpenSans.Primary px py>
          {section.title}
        </OpenSans.Primary>
      )}
      renderItem={({ item }) => (
        <Layout.Column key={item.currency}>
          <Layout.Row px py>
            <OpenSans.Primary>{item.currency}</OpenSans.Primary>
            <OpenSans.Primary px weight="bold-700">
              {/* TODO @wmatous @buchereli amounts are cents from stripe */}
              {formatCurrency(StripeToEEParse.amountToTotal(item.amount, 'usd'))}
            </OpenSans.Primary>
          </Layout.Row>
          <Separator.Horizontal />
        </Layout.Column>
      )}
      sections={[
        { title: 'Available', data: balance.issuing?.available ?? [] },
        ...(balance.metadata?.pending?.length
          ? [{ title: 'Pending', data: balance.metadata?.pending ?? [] }]
          : []),
      ]}
    />
  );
};
