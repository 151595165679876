import { Layout, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';
import { Transition } from 'react-transition-group';

export const duration = 300;

export const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
};

export const transitionStyles: { [key: string]: object } = {
  entering: { position: 'absolute', width: 0, right: 0, top: 0, bottom: 0 },
  entered: { position: 'absolute', width: '100%', maxWidth: 600, right: 0, top: 0, bottom: 0 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const TransitionSidebar: React.FC<
  React.PropsWithChildren<{
    modalOpen: boolean;
  }>
> = ({ modalOpen, children }) => {
  return (
    <Transition in={modalOpen} timeout={0}>
      {(state) => {
        if (modalOpen) {
          return (
            <>
              <Layout.Column
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                  zIndex: zIndex.ModalOverlay,
                }}
                bg="modalBackground"
              >
                <Layout.Column style={{ ...defaultStyle, ...transitionStyles[state], flex: 1 }}>
                  {children}
                </Layout.Column>
              </Layout.Column>
            </>
          );
        }
      }}
    </Transition>
  );
};
