import { PaymentLink } from '@easy-expense/data-schema-v2';
import { WebEnv } from '@easy-expense/env-mobile';
import { STAGING_ENV } from '@easy-expense/env-shared';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React from 'react';

import { CheckoutForm } from './CheckoutForm.component';

export const StripePaymentForm: React.FC<
  React.PropsWithChildren<{ invoice: Partial<PaymentLink> }>
> = ({ invoice }) => {
  const [clientSecret, setClientSecret] = React.useState('');

  const stripePromise = loadStripe(STAGING_ENV.stripe.publishable_key, {
    stripeAccount: invoice.connectedAccountID,
  });

  const appearance: Appearance = {
    theme: 'flat',

    variables: {
      spacingUnit: '4px',
      spacingGridRow: '20px',
    },
  };

  const getPaymentIntentURL = () => {
    const ENDPOINT = '/stripe-stripeEndpoint/payment-intent';
    const url = new URL(window.location.href);
    const baseDomain = url.hostname.split('.').slice(-2).join('.');
    if (baseDomain === 'localhost') {
      return `http://127.0.0.1:5001/easy-expense-dev/us-central1${ENDPOINT}`;
    }
    return `${WebEnv.firebase.cloudPrefix}/${ENDPOINT}`;
  };

  React.useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await axios.post(getPaymentIntentURL(), {
          accountID: invoice.connectedAccountID,
          paymentID: invoice.key,
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating Payment Intent:', error);
      }
    };

    createPaymentIntent();
  }, []);

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <CheckoutForm secret={clientSecret} />
        </Elements>
      )}
    </>
  );
};
