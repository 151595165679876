import { Analytics } from '@easy-expense/analytics-client';
import {
  currentUserEntityFields,
  updateReportsEntities,
  getCachedReports,
  useWorkspaceSettings,
  useCachedCategories,
} from '@easy-expense/data-firestore-client';
import { Category, Expense, ExpenseSchema, TaxRate, Vendor } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import cuid from 'cuid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CurrencyField } from './CurrencyInput.component';
import { DateField } from './DateField.component';
import { NotesField } from './NotesField.component';
import { PaymentMethodField } from './PaymentMethodField.component';
import { ReceiptViewer } from './ReceiptViewer.component';
import { ReceiptsField } from './ReceiptsField.component';
import { ReportsField } from './ReportsField.component';
import { Button } from '../../Button.components';
import LoadingSpinner from '../../LoadingSpinner.component';
import { CloseButton } from '../../Shared/CloseButton.component';
import { InsertDropdown } from '../../Shared/Dropdown/InsertableDropdown.component';
import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

export const ExpenseEdit: React.FC<React.PropsWithChildren<{ expenseKey?: string }>> = ({
  expenseKey,
}) => {
  const [expense, setExpense] = React.useState<Partial<Expense>>(
    Data.expenses.getByKey(expenseKey) ?? {},
  );
  const expenseKeyRef = React.useRef<string | undefined>(expenseKey);
  const [reportKeys, _setReportKeys] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<{ [k: string]: string[] }>();
  const allVendors = Data.vendors.use();
  const categories: Category[] = useCachedCategories();

  const navigate = useNavigate();
  const [selectedReceiptIndex, setSelectedReceiptIndex] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const [renderKey, setRenderKey] = React.useState(cuid()); // used to rerender children
  const [newVendorName, setNewVendorName] = React.useState<string>('');
  const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
  const { workspaceSettings } = useWorkspaceSettings();

  const taxRate: TaxRate | undefined = React.useMemo(() => {
    const trackedTaxRate = workspaceSettings?.taxRates.find((tr) => tr.tracked);
    return trackedTaxRate;
  }, [workspaceSettings]);

  React.useEffect(() => {
    if (expenseKey) {
      const expense = Data.expenses.getByKey(expenseKey);

      if (!expense) {
        expenseKeyRef.current = undefined;
      }
    }
  }, []);

  function onMobile() {
    if (screenWidth < 768) {
      if (
        window.confirm(
          'The Experience is better on the App! Press "OK" to download the app. "CANCEL" will load this website ',
        )
      ) {
        window.location.href = 'https://app.easy-expense.com/app-download';
      }
    }
  }

  React.useEffect(() => {
    onMobile();
    Analytics.track('edit_expense_entered');
  }, []);

  function onSave() {
    Analytics.track('edit_expense_changed');
    setErrors({});

    let vendorKey = expense.vendor;
    if (newVendorName) {
      vendorKey = Data.vendors.create({ value: { name: newVendorName } });
    }

    if (!expense.date) {
      setErrors((errors) => ({ ...errors, date: ['invalid date'] }));
      return;
    }

    try {
      const parsedExpense = ExpenseSchema.safeParse({
        ...expense,
        ...currentUserEntityFields('create'),
        vendor: vendorKey,
      });

      if (!parsedExpense.success) {
        setErrors((errors) => ({ ...errors, ...parsedExpense.error.formErrors.fieldErrors }));
        return;
      }
    } catch (err) {
      console.error('error saving expense ', err);
      alert('Error saving expense. Please contact support.');
      return;
    }

    try {
      let resolvedExpenseKey = expenseKeyRef.current;
      if (expenseKeyRef.current) {
        Data.expenses.update(expenseKeyRef.current, {
          ...expense,
          vendor: vendorKey,
        });
      } else {
        resolvedExpenseKey = Data.expenses.create({
          ...expense,
          vendor: vendorKey,
        });
      }

      if (resolvedExpenseKey) {
        expenseKeyRef.current = resolvedExpenseKey;
        const oldReports = getCachedReports(resolvedExpenseKey).map((r) => r.key) ?? [];
        const reportsToRemove = oldReports.filter((r) => !reportKeys.includes(r));

        updateReportsEntities({
          reportKeys,
          entityType: 'expenses',
          entityKeys: [resolvedExpenseKey],
        });

        updateReportsEntities({
          reportKeys: reportsToRemove,
          entityType: 'expenses',
          entityKeys: [resolvedExpenseKey],
          remove: true,
        });

        navigate(`/transactions`);
      }
    } catch (e) {
      alert('Error saving expense. Please contact support.');
    }
  }

  function onDiscard() {
    navigate(`/transactions`);
  }

  function isNewExpense() {
    return !expenseKeyRef.current;
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100%' }}>
      {loading ? (
        <Layout.Column
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: zIndex.LoadingOverlay,
          }}
        >
          <LoadingSpinner />
        </Layout.Column>
      ) : null}
      <Layout.Row style={{ height: '100%', width: '100%' }}>
        {screenWidth > 800 ? (
          <Layout.Column
            style={{ minWidth: 600, maxWidth: 800, height: '100vh' }}
            grow
            className="hide-on-mobile"
          >
            <ReceiptViewer
              receipts={expense.receipts ?? []}
              onChange={(receipts: string[]) => {
                setExpense((expense) => ({ ...expense, receipts }));
              }}
              selectedReceiptIndex={selectedReceiptIndex}
              setSelectedReceiptIndex={setSelectedReceiptIndex}
              setLoading={setLoading}
              isNewExpense={isNewExpense()}
              setExpense={(expense) => {
                setExpense(expense);
                setRenderKey(cuid()); // causes a rerender of all children
              }}
            />
          </Layout.Column>
        ) : null}

        <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
          <Layout.Row>
            <Spacer.Flex style={{ maxWidth: 150 }} />
            <Layout.Column style={{ width: 450 }}>
              <Spacer.Vertical size={32} />

              <InsertDropdown
                header={getTranslation('Vendor')}
                placeholder={getTranslation('Vendor Name')}
                selectedKey={expense.vendor}
                autoCompleteValues={allVendors}
                onSelect={(v) => {
                  if (v?.key) {
                    setExpense((expense) => ({ ...expense, vendor: v?.key }));
                  } else if (v?.value?.name) {
                    setNewVendorName(v?.value?.name);
                  }
                }}
                error={!!errors?.vendor}
                iconicIcon="storefront-outline"
              />

              <Spacer.Vertical size={'s-16'} />

              <SearchDropdown
                header={getTranslation('Category')}
                placeholder={getTranslation('Category Name')}
                selectedKey={expense.category}
                autoCompleteValues={categories}
                onSelect={(c) => {
                  if (c?.key) {
                    setExpense((expense) => ({ ...expense, category: c?.key }));
                  }
                }}
                error={!!errors?.category}
                iconicIcon="storefront-outline"
              />

              <Spacer.Vertical size={'s-16'} />

              <Layout.Row justify>
                <Layout.Column style={{ width: taxRate ? '50%' : '100%', paddingRight: '8px' }}>
                  <CurrencyField
                    key={`total_${renderKey}`}
                    error={!!errors?.total}
                    onChange={(total) => setExpense((expense) => ({ ...expense, total }))}
                    initialValue={expense.total}
                    label={
                      getTranslation('Total') +
                      ((expense.total ?? 0) < 0 ? ' (' + getTranslation('refund') + ')' : '')
                    }
                    allowNegative
                  />
                </Layout.Column>
                {taxRate === undefined ? null : (
                  <Layout.Column style={{ width: '50%', paddingLeft: '8px' }}>
                    <CurrencyField
                      key={`tax_${renderKey}`}
                      error={!!errors?.tax}
                      onChange={(tax) => setExpense((expense) => ({ ...expense, tax }))}
                      initialValue={expense?.tax ?? undefined}
                      label={taxRate.name}
                    />
                  </Layout.Column>
                )}
              </Layout.Row>

              <Spacer.Vertical size={'s-16'} />

              <PaymentMethodField
                key={`paymentMethod_${renderKey}`}
                initialPaymentMethodKey={expense.paymentMethod}
                onChange={(paymentMethodKey) =>
                  setExpense((expense) => ({ ...expense, paymentMethod: paymentMethodKey }))
                }
              />

              <Spacer.Vertical size={'s-16'} />

              <DateField
                key={`date_${renderKey}`}
                error={!!errors?.date}
                initialDate={expense.date}
                disabled={loading}
                onChange={(date) => setExpense((expense) => ({ ...expense, date }))}
              />

              <Spacer.Vertical size={'s-16'} />

              <ReportsField expenseKey={expense.key} onChange={(rks) => _setReportKeys([...rks])} />

              <Spacer.Vertical size={'s-16'} />

              <NotesField
                key={`notes_${renderKey}`}
                initialNote={expense.desc}
                onChange={(notes) => setExpense((expense) => ({ ...expense, desc: notes }))}
              />

              <Spacer.Vertical size={'s-16'} />

              <ReceiptsField
                receipts={expense.receipts ?? []}
                onChange={(receipts) => setExpense((expense) => ({ ...expense, receipts }))}
                selectedReceiptIndex={selectedReceiptIndex}
                setSelectedReceiptIndex={setSelectedReceiptIndex}
                setLoading={setLoading}
                setExpense={setExpense}
              />

              <Layout.Row
                style={{
                  justifyContent: 'start',
                  position: 'fixed',
                  bottom: '20px',
                  zIndex: 2,
                  minWidth: 600,
                }}
              >
                <Button.Secondary
                  onClick={onDiscard}
                  radius={50}
                  style={{
                    border: '2px solid #fff',
                    boxShadow: '0 0 4px rgba(255, 255, 255, 1)',
                  }}
                >
                  <Icon
                    size={15}
                    color={theme.colors.brandPrimary}
                    style={{ paddingRight: 10 }}
                    name="chevron-back"
                  />
                  <OpenSans.Primary
                    size={15}
                    weight="bold-700"
                    style={{ color: theme.colors.brandPrimary }}
                  >
                    Discard
                  </OpenSans.Primary>
                </Button.Secondary>

                <Spacer.Horizontal size={'m-18'} />

                <Button.Primary
                  onClick={onSave}
                  radius={50}
                  style={{
                    border: '2px solid #fff',
                    boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
                  }}
                >
                  <OpenSans.Custom
                    size={15}
                    weight="bold-700"
                    style={{ color: theme.colors.buttonWhite }}
                  >
                    Save Expense
                  </OpenSans.Custom>
                  <Icon
                    size={15}
                    color={theme.colors.buttonWhite}
                    style={{ paddingLeft: 10 }}
                    name="chevron-forward"
                  />
                </Button.Primary>
              </Layout.Row>

              <Spacer.Vertical size={100} />
            </Layout.Column>
            <Spacer.Flex />
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>

      <CloseButton onClose={() => navigate(`/transactions`)} />
    </Layout.Column>
  );
};
