import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { DateField } from '../Expense/Edit/DateField.component';
import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

function getLastDayOfMonth(year: string, month: string) {
  const yearNumber = parseInt(year);
  const monthNumber = parseInt(month);
  const lastDay = new Date(yearNumber, monthNumber, 0).getDate();
  return lastDay.toString().padStart(2, '0');
}

export const ExportDateFilter: React.FC<{
  dateFilters: {
    startDate: string;
    endDate?: string;
  };
  setDateFilters: (filters: { startDate: string; endDate?: string }) => void;
}> = ({ dateFilters, setDateFilters }) => {
  const [range, setRange] = React.useState<string>('year');

  React.useEffect(() => {
    const thisYear = new Date().getFullYear().toString();
    const thisMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const lastDayOfMonth = getLastDayOfMonth(thisYear, thisMonth);
    const today = new Date().toLocaleDateString('en-ca');
    switch (range) {
      case 'year':
        setDateFilters({
          startDate: `${thisYear}-01-01`,
          endDate: today,
        });
        break;
      case 'month':
        setDateFilters({
          startDate: `${thisYear}-${thisMonth}-01`,
          endDate: `${thisYear}-${thisMonth}-${lastDayOfMonth}`,
        });

        break;
      default:
        break;
    }
  }, [range]);

  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Date Range')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Range')}
          placeholder={''}
          selectedKey={range}
          autoCompleteValues={[
            { key: 'custom', value: { name: 'Custom', iconicIcon: 'calendar-outline' } },
            { key: 'year', value: { name: 'Year To Date', iconicIcon: 'calendar-outline' } },
            { key: 'month', value: { name: 'Month', iconicIcon: 'calendar-outline' } },
          ]}
          onSelect={(c) => {
            if (c?.key) {
              setRange(c.key);
            }
          }}
          error={false}
          iconicIcon="calendar-outline"
        />
        <Spacer.Horizontal />
        <DateField
          key="startDate"
          label={'Start Date'}
          initialDate={dateFilters.startDate}
          onChange={(date) => {
            setDateFilters({ ...dateFilters, startDate: date });
            setRange('custom');
          }}
          hideIcons={true}
        />
        <Spacer.Horizontal />
        <DateField
          key="endDate"
          label={'End Date'}
          initialDate={dateFilters.endDate}
          onChange={(date) => {
            setDateFilters({ ...dateFilters, endDate: date });
            setRange('custom');
          }}
          hideIcons={true}
        />
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
