import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TeamsInvite } from '../../components/Onboarding/TeamsInvite.component';

export const TeamsInvitePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <TeamsInvite
      onNavigate={() => {
        navigate('/onboarding/get-started');
      }}
    />
  );
};
