import { Analytics } from '@easy-expense/analytics-client';
import { getLeaveOrganizationFn } from '@easy-expense/auth-client';
import { useCurrentWorkspace } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const WorkspaceLeave: React.FC = () => {
  const workspace = useCurrentWorkspace();
  const [leaveWorkspaceText, setLeaveWorkspaceText] = React.useState<string>();

  async function onLeaveWorkspace() {
    if (
      confirm(
        getTranslation(
          'Are you sure you want to leave? You will no longer be able to view or edit data in this workspace.',
        ),
      )
    ) {
      Analytics.track('leave_workspace', { org: workspace.org });
      try {
        await getLeaveOrganizationFn()({
          organizationID: workspace.org ?? '',
        });
      } catch (e) {
        alert(getTranslation('An error occurred. Please try again'));
      }
    }
  }

  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column style={{ maxWidth: 350 }}>
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Permanently leave workspace')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16} weight="bold-700" color="destructive">
            {getTranslation(
              'Warning! You will not be able to rejoin the workspace without an invite',
            )}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <LabelTextField
          label={`${getTranslation('Type')} "${workspace.name}" ${getTranslation(
            'then press enter',
          )}`}
          styles={{
            backgroundColor: theme.colors.destructiveXLight,
            borderColor: theme.colors.destructive,
          }}
        >
          <OpenSans.Input
            name="leaveWorkspace"
            value={leaveWorkspaceText}
            type="text"
            weight="bold-700"
            placeholder={getTranslation(`${workspace.name}`)}
            grow
            size={28}
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLeaveWorkspaceText(event.target.value)
            }
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (
                event.key === 'Enter' &&
                workspace.name.length > 0 &&
                leaveWorkspaceText === `${workspace.name}`
              ) {
                onLeaveWorkspace();
              }
            }}
            autoComplete="off"
          />
        </LabelTextField>
      </Layout.Column>
    </Layout.Row>
  );
};
