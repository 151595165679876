import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image } from 'react-native';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from './Button.components';
import { startAppleLogIn, startGoogleLogIn } from '../firebase/auth';
import { useMounted } from '../hooks/useMounted';

const GoogleAuthButton: React.FC<React.PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
}) => {
  return (
    <Button.White onClick={onClick} radius={8} py center>
      <Layout.Row center>
        <Image source={require('../images/icons/Google.png')} style={{ width: 26, height: 26 }} />
        <Spacer.Horizontal />
        <OpenSans.Primary size="l-20">Sign in with Google</OpenSans.Primary>
      </Layout.Row>
    </Button.White>
  );
};

export const SocialAuth: React.FC<{
  loginHint?: string | null;
  onSuccess?: () => Promise<void> | null;
}> = ({ loginHint, onSuccess }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // @ts-expect-error
  const from = location.state?.from?.pathname || '/';
  const { current: mounted } = useMounted();
  const [working, setWorking] = React.useState<'apple' | 'google' | false>(false);

  React.useEffect(() => {
    switch (working) {
      case 'google':
        if (!working) {
          return;
        }

        startGoogleLogIn(loginHint)
          .then(() => (onSuccess ? onSuccess() : navigate(from, { replace: true })))
          .catch((e) => {
            switch (e.code) {
              case 'auth/too-many-requests':
                alert('Too many authentication requests. Try later.');
                break;
              case 'auth/user-not-found':
                alert('Invalid credentials.');
                break;
              case 'auth/wrong-password':
                alert('Invalid credentials.');
                break;
              case 'auth/cancelled-popup-request':
                break;
              case 'auth/popup-closed-by-user':
                break;
              default:
                alert('Cannot login. Please contact support.');
                break;
            }
          })
          .finally(() => setWorking(false));
        break;
      case 'apple':
        if (!working) {
          return;
        }
        startAppleLogIn()
          .then(() => (onSuccess ? onSuccess() : navigate(from, { replace: true })))
          .catch((e) => {
            switch (e.code) {
              case 'auth/too-many-requests':
                alert('Too many authentication requests. Try later.');
                break;
              case 'auth/user-not-found':
                alert('Invalid credentials.');
                break;
              case 'auth/wrong-password':
                alert('Invalid credentials.');
                break;
              case 'auth/cancelled-popup-request':
                break;
              case 'auth/popup-closed-by-user':
                break;
              default:
                alert('Cannot login. Please contact support.');
                break;
            }
          })
          .finally(() => setWorking(false));
        break;
    }
  }, [working, mounted, navigate, from]);

  return (
    <Layout.Column>
      <GoogleAuthButton onClick={() => setWorking('google')} />
      <Spacer.Vertical />
      <Layout.PressableRow
        onClick={() => setWorking('apple')}
        style={{ backgroundColor: 'transparent' }}
      >
        <Image
          source={require('../images/icons/appleid_button.png')}
          style={{ width: '100%', height: 55, marginTop: 5, borderRadius: 8 }}
        />
      </Layout.PressableRow>
    </Layout.Column>
  );
};
