import { useCachedReportWithData } from '@easy-expense/data-firestore-client';
import { ReportWithData } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../Button.components';
import { ExpenseList } from '../Expense/ExpenseList';
import { CloseButton } from '../Shared/CloseButton.component';

export const ReportAddTransactions: React.FC<
  React.PropsWithChildren<{
    reportKey?: string;
    onClose: () => void;
    report: ReportWithData;
    setReportDirty?: (isDirty: boolean) => void;
  }>
> = ({ reportKey, onClose, report, setReportDirty }) => {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState<string[]>([]);

  if (!report) {
    navigate('/404');
  }

  React.useEffect(() => {
    const newSelected: string[] = [];
    if (!report) {
      return;
    }
    report.expenses.forEach((expense) => {
      newSelected.push(expense);
    });
    setSelected(newSelected);
  }, []);

  async function onDone() {
    onClose();
  }

  const header = (
    <Layout.Row py align>
      <OpenSans.Primary weight="bold-700" size={32}>
        {getTranslation('Select and add to')} {report?.name}
      </OpenSans.Primary>
      <Spacer.Horizontal size={32} />
      <Layout.Column>
        <Button.Primary radius={100} onClick={onDone}>
          <Icon name="checkmark-done" size={20} color={theme.colors.white} />
          <Spacer.Horizontal size={8} />
          {getTranslation('Done')}
        </Button.Primary>
      </Layout.Column>
    </Layout.Row>
  );

  return (
    <Layout.Row px={24} style={{ height: '100%', width: '100%' }}>
      <Spacer.Flex />
      <Layout.Column style={{ height: '100%', minWidth: 1100 }}>
        <ExpenseList
          onRowClick={(expense) => {
            const newSelected = [...selected];
            const expenseKeys = report?.expenses ? [...report?.expenses] : [];
            if (setReportDirty) {
              setReportDirty(true);
            }

            if (selected.includes(expense.key)) {
              newSelected.splice(newSelected.indexOf(expense.key), 1);
              report.expenses = expenseKeys.filter((e) => e !== expense.key);
              report.total += expense.total;
            } else {
              newSelected.push(expense.key);
              report.expenses = [...expenseKeys, expense.key];
              report.total -= expense.total;
            }

            setSelected(newSelected);
          }}
          showBulk={true}
          header={header}
          selected={selected}
          setSelected={setSelected}
          report={report}
        />
      </Layout.Column>
      <Spacer.Flex />
      <CloseButton onClose={onDone} />
    </Layout.Row>
  );
};
