import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Sidebar } from './Sidebar.component';
import { WorkspaceInvite } from './WorkspaceInvite.web.component';

export const AppLayout: React.FC = () => {
  return (
    <Layout.Row style={{ width: '100%', height: '100vh', minWidth: 1400 }}>
      <Sidebar />
      <Layout.Column style={{ width: '100%', height: '100vh' }}>
        <WorkspaceInvite />

        <Layout.Column grow style={{ overflow: 'scroll' }}>
          <Outlet />
        </Layout.Column>

        <Layout.Row justify py={8}>
          <OpenSans.Secondary size="xs-12">
            © {new Date().getFullYear()} Easy Expense Tracker Inc. All rights reserved.
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
    </Layout.Row>
  );
};
