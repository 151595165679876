import { LayoutProps } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { CardContainer } from './CardContainer.component';

export const Card: React.FC<
  React.PropsWithChildren<
    {
      footer?: { text: string; onPress: () => void } | (() => React.ReactNode);
      header?: { title: string; subTitle?: string } | (() => React.ReactNode);
      grow?: boolean;
    } & LayoutProps
  >
> = (props) => {
  const { header, footer, children, ...layout } = props;
  return (
    <CardContainer {...layout}>
      {!!header && (
        <>
          <Layout.Column py px>
            {typeof header === 'object' ? (
              <>
                <OpenSans.Primary>{header.title}</OpenSans.Primary>
                {!!header.subTitle && (
                  <>
                    <Spacer.Vertical size={4} />
                    <OpenSans.Secondary size={14}>{header.subTitle}</OpenSans.Secondary>
                  </>
                )}
              </>
            ) : (
              header()
            )}
          </Layout.Column>
        </>
      )}
      {children}
      {!!footer &&
        (typeof footer === 'object' ? (
          <Layout.PressableRow py px onClick={footer.onPress} align justify="space-between">
            <OpenSans.Pressable>{footer.text}</OpenSans.Pressable>
            {/* <Icon name="chevron-forward" size={24} iconColor="primary" /> */}
          </Layout.PressableRow>
        ) : (
          footer()
        ))}
    </CardContainer>
  );
};
