import { CategoryValue } from '@easy-expense/data-schema-v2';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { darken, lighten } from 'polished';
import React from 'react';

export const CategoryBadge: React.FC<React.PropsWithChildren<{ category?: CategoryValue }>> = ({
  category,
}) => {
  if (!category) {
    return null;
  }

  const categoryColor = hueToColor(category.color ?? 0);

  return (
    <Layout.Row style={{ width: 200 }}>
      <Layout.Row
        radius={4}
        style={{ backgroundColor: lighten(0.35, `${categoryColor}`) }}
        py={4}
        px={8}
      >
        <OpenSans.Primary
          size="s-12"
          singleLine={{ maxWidth: '160px' }}
          style={{
            color: darken(0.35, `${categoryColor}`),
          }}
        >
          {category.icon}&nbsp;&nbsp;{category.name}
        </OpenSans.Primary>
      </Layout.Row>
    </Layout.Row>
  );
};
