import { Analytics } from '@easy-expense/analytics-client';
import { useUserSettings } from '@easy-expense/data-firestore-client';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const useAdsClickID = () => {
  const location = useLocation();
  const userSettings = useUserSettings();

  React.useEffect(() => {
    const gclid = localStorage.getItem('gclid');
    if (gclid && userSettings.data?.userID && !userSettings.data.settings.googleAdsClickID) {
      Analytics.identify(userSettings.data?.userID, { gclid });
      userSettings.updateUserSetting({ googleAdsClickID: gclid });
    }
  }, [userSettings.data]);

  React.useEffect(() => {
    if (userSettings.data?.settings.googleAdsClickID) {
      localStorage.removeItem('gclid');
    }
  }, [userSettings.data?.settings.googleAdsClickID]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('gclid')) {
      localStorage.setItem('gclid', params.get('gclid') ?? '');
    }
  }, [location.search]);
};
