import { Analytics } from '@easy-expense/analytics-client';
import { addWorkspaceMember, useWorkspaceKeysStore } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { MemberAddRow } from './TeamMemberRow.component';
import { useIsMobile, useWindowDimensions } from '../../hooks/useWindow';
import { Button } from '../Button.components';
import { ErrorText } from '../Form/Fields/ErrorText.component';

export type TeamsInviteProps = { email: string; role: string };
const defaultTeamMembers: TeamsInviteProps[] = [
  { email: '', role: 'member' },
  { email: '', role: 'member' },
  { email: '', role: 'member' },
];
const MAX_INVITES = 10;

export const TeamsInvite: React.FC<React.PropsWithChildren<{ onNavigate: () => void }>> = ({
  onNavigate,
}) => {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [teamMembers, setTeamMembers] = React.useState<TeamsInviteProps[]>(defaultTeamMembers);
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());

  function updateTeamMember(index: number, teamMember: TeamsInviteProps) {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index] = teamMember;
    setTeamMembers(newTeamMembers);
  }

  function onAddTeamMembers() {
    if (teamMembers.length >= MAX_INVITES) {
      setShowError(true);
      return;
    }
    setTeamMembers([...teamMembers, { email: '', role: 'member' }]);
  }

  function onInviteTeamMembers() {
    const invitesSent = teamMembers.filter((teamMember) => teamMember.email).length;
    Analytics.track('team_onboarding_invite_team_members', { invitesSent });

    for (const teamMember of teamMembers) {
      addWorkspaceMember(teamMember.email, currentWorkspacePath);
    }
    onNavigate();
  }

  const { width } = useWindowDimensions();
  const isMobile = useIsMobile();

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn>
        <OpenSans.Primary weight="bold-700" size="3xl-38">
          {getTranslation('Invite Users')}
        </OpenSans.Primary>
        <OpenSans.Primary size={12} style={{ width: 300 }} center>
          {getTranslation(
            'By default users will only be able to see their own expenses. You can change this later.',
          )}
        </OpenSans.Primary>
        {showError ? (
          <>
            <Spacer.Vertical size={8} />

            <Layout.Row style={{ width: '100%' }} center>
              <ErrorText>
                {getTranslation(
                  "Let's start with 10 invites. You'll be able to send more once you are done signing up",
                )}
              </ErrorText>
            </Layout.Row>
          </>
        ) : null}

        <Spacer.Vertical size={8} />
        <Layout.Row px py>
          <Layout.TableBody center>
            <Layout.TableRow
              align
              style={{
                paddingBottom: 0,
                paddingTop: 8,
              }}
            >
              <Layout.TableCell
                center
                style={{ paddingBottom: 0, paddingTop: 8, width: isMobile ? width : 350 }}
              >
                <OpenSans.Custom size={16} weight="bold-700" color="secondary">
                  {getTranslation('Email')}
                </OpenSans.Custom>
              </Layout.TableCell>

              {/* <Layout.TableCell center style={{ paddingBottom: 0, paddingTop: 8 }}>
              <Layout.Row align justify="space-between">
                <OpenSans.Custom size={16} weight="bold-700" color="secondary">
                  {getTranslation('Permissions')}
                </OpenSans.Custom>
                <Icon name="help-circle-outline" size={16} />
              </Layout.Row>
            </Layout.TableCell> */}
            </Layout.TableRow>

            {teamMembers.map((teamMember, index) => (
              <MemberAddRow
                key={index}
                teamMember={teamMember}
                onChange={(teamMember) => updateTeamMember(index, teamMember)}
              />
            ))}

            <Layout.TableRow onClick={() => onAddTeamMembers()} style={{ width: '100%' }}>
              <Layout.TableCell>
                <Layout.Row
                  align
                  style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                >
                  <OpenSans.Custom size={12} color={'brandPrimary'}>
                    {getTranslation('Add Another')}
                  </OpenSans.Custom>
                  <Spacer.Horizontal size={4} />
                  <Icon name="add-circle-outline" size={16} color={theme.colors.brandPrimary} />
                </Layout.Row>
              </Layout.TableCell>
            </Layout.TableRow>
          </Layout.TableBody>
        </Layout.Row>

        <Spacer.Vertical size={32} />

        <Button.Primary
          onClick={onInviteTeamMembers}
          radius={8}
          px
          py
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
            width: isMobile ? '100%' : 350,
          }}
          center
        >
          <OpenSans.Custom size={16} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
            {getTranslation('Add Teammates')}
          </OpenSans.Custom>
        </Button.Primary>
        <Spacer.Vertical />
        <Layout.PressableRow center onClick={onNavigate}>
          <OpenSans.Custom size={15} weight="bold-700" bg="white" color={'brandPrimary'}>
            {getTranslation('Skip, continue alone')}
          </OpenSans.Custom>
        </Layout.PressableRow>
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
