import { ReportWithData } from '@easy-expense/data-schema-v2';
import { useIntlStore } from '@easy-expense/intl-client';
import { Layout, OpenSans, Separator } from '@easy-expense/ui-web-core';
import React from 'react';

import { Card } from '../Card.component';
import { TripList } from '../TripList';

export const TripCard: React.FC<{
  report: ReportWithData;
}> = ({ report }) => {
  const { formatCurrency } = useIntlStore();

  return (
    <Card
      header={() => {
        return (
          <Layout.Row align grow>
            <Layout.Column grow>
              <OpenSans.Primary size="l-20">🚗 Trips</OpenSans.Primary>
              <OpenSans.Secondary size="s-16">{`${report.trips.length} trip${
                report.trips.length !== 1 ? 's' : ''
              } | ${formatCurrency(report.tripTotal)}`}</OpenSans.Secondary>
            </Layout.Column>
          </Layout.Row>
        );
      }}
    >
      <Separator.Horizontal />
      <TripList filter={(t) => report.trips.includes(t.key)} />
    </Card>
  );
};
