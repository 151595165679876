import {
  addWorkspaceMember,
  canModifyUsers,
  useCurrentWorkspace,
  useCachedMembers,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, Separator, zIndex } from '@easy-expense/ui-web-core';
import { MemberExistsMessage } from '@easy-expense/utils-shared';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { Button } from '../components/Button.components';
import { LabelTextField } from '../components/LabelTextField.component';
import LoadingSpinner from '../components/LoadingSpinner.component';
import { Modal } from '../components/Shared/Modal.component';
import { EmailSchema } from '../components/SignUpInvited.form';
import { WorkspaceMemberTable } from '../components/WorkspaceMembers/WorkspaceMemberTable';
import { auth } from '../firebase/app';

const InviteFormLoader: React.FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => {
  if (!isSubmitting) {
    return null;
  }
  return (
    <Layout.Column
      style={{
        width: '100vh',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: zIndex.LoadingOverlay,
      }}
    >
      <LoadingSpinner />
    </Layout.Column>
  );
};

export const Workspace: React.FC = () => {
  return <WorkspaceManagementScreen />;
};

const InviteUserForm: React.FC<{
  isSubmitting: boolean;
  handleSubmit: (emails: string) => Promise<void>;
  onClose: () => void;
}> = ({ isSubmitting, handleSubmit, onClose }) => {
  const [isFocused, setFocused] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [emails, setEmails] = React.useState<string>();

  const [dirty, setDirty] = React.useState(false);

  let emailErrorMessage = undefined;
  if ((dirty || isFocused) && emails?.length === 0) {
    emailErrorMessage = getTranslation('Email is required');
  } else if (dirty && !isFocused && !emailValid) {
    emailErrorMessage = getTranslation('Invalid email');
  }

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(emails);
    setEmailValid(isValid.success);
  }, [emails]);

  return (
    <Layout.Column bg="navHeaderBackground" px py style={{ maxWidth: 800 }}>
      <InviteFormLoader isSubmitting={isSubmitting} />
      <OpenSans.Primary weight="bold-700" size="xl-28">
        {getTranslation('Invite Users by Email')}
      </OpenSans.Primary>
      <Spacer.Vertical size={12} />
      <OpenSans.Primary>
        <span>
          {getTranslation('Invited teamed members will have the ')}
          <b> {getTranslation('Member')}</b>
          {getTranslation(
            ' role. This means they will only be able to see and edit their own data. User roles can be changed by an admin later.',
          )}
        </span>
      </OpenSans.Primary>

      <Spacer.Vertical size={12} />
      <LabelTextField
        label="Email"
        active={isFocused}
        errorMessage={emailErrorMessage}
        error={!!emailErrorMessage && dirty}
      >
        <OpenSans.Input
          name="emails"
          value={emails}
          type="email"
          weight="bold-700"
          placeholder="name@mail.com"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmails(event.target.value);
            setDirty(true);
          }}
          autoComplete="off"
          autoFocus
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
            setDirty(true);
          }}
        />
      </LabelTextField>

      <Spacer.Vertical size={64} />
      <Layout.Column grow />
      <Layout.Row>
        <Button.Secondary onClick={() => onClose()} content="Cancel" radius={50} />
        <Spacer.Horizontal size={12} />
        <Button.Primary
          onClick={() => handleSubmit(emails ?? '')}
          active={emailValid && dirty && !isSubmitting}
          content="Send Invite"
          radius={50}
        />
      </Layout.Row>
    </Layout.Column>
  );
};

const InviteButton: React.FC = () => {
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const workspace = useCurrentWorkspace();
  const members = useCachedMembers();
  const [user] = useAuthState(auth);
  const self = members?.find((mem) => mem.userID === user?.uid);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const inviteUsers = async (userEmails: string) => {
    setIsSubmitting(true);
    //TODO: Make user emails accept multiple emails at once
    const {
      data: { success, message },
    } = await addWorkspaceMember(userEmails, currentWorkspacePath);

    if (success) {
      setModalOpen(false);
    } else {
      console.error(message);
      alert(MemberExistsMessage === message ? MemberExistsMessage : 'An error occurred');
    }
    setIsSubmitting(false);
  };

  if (self && canModifyUsers(self, workspace?.createdBy)) {
    return (
      <>
        <Layout.PressableRow
          onClick={() => setModalOpen(true)}
          bg="inputBackground"
          border={[1, 'solid', 'inputBorder']}
          py={4}
          px
          radius={100}
          align
        >
          <Icon name="add-outline" size={16} color={theme.colors.primary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary>{getTranslation('Invite to Workspace')}</OpenSans.Primary>
          <Spacer.Horizontal size={8} />
          <Icon name="chevron-down" size={16} color={theme.colors.primary} />
        </Layout.PressableRow>

        <Modal showModal={modalOpen}>
          <InviteUserForm
            isSubmitting={isSubmitting}
            onClose={() => setModalOpen(false)}
            handleSubmit={(emails: string) => inviteUsers(emails)}
          />
        </Modal>
      </>
    );
  }

  return null;
};

const MemberActions: React.FC<{ navigate: ReturnType<typeof useNavigate> }> = ({ navigate }) => (
  <Layout.Row align>
    <OpenSans.Primary size={32} weight="bold-700">
      {getTranslation('Workspace Members')}
    </OpenSans.Primary>
    <Spacer.Horizontal size={32} />
    <InviteButton />
  </Layout.Row>
);

function WorkspaceManagementScreen() {
  const cachedMembers = useCachedMembers();

  const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string | undefined>(undefined);

  return (
    <Layout.Column px>
      <Layout.Column px>
        <Spacer.Vertical size={16} />

        <MemberActions navigate={navigate} />

        <Spacer.Vertical size={24} />

        <Layout.Row>
          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Admin')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('Invite users and modify settings')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>

          <Spacer.Horizontal size={16} />

          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Manager')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('View and edit all expenses')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>

          <Spacer.Horizontal size={16} />

          <Layout.Row px={24} py border={[1, 'solid', 'primary']} radius={8} align bg="white">
            <Layout.Column>
              <OpenSans.Primary weight="bold-700" size="s-16">
                {getTranslation('Member')}
              </OpenSans.Primary>
              <OpenSans.Primary size="xs-12">
                {getTranslation('Only view and edit their own expenses')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Row>
      </Layout.Column>

      <Spacer.Vertical size={10} />

      <Separator.Horizontal />

      <WorkspaceMemberTable />
    </Layout.Column>
  );
}
