import { useWorkspaceStore } from '@easy-expense/data-firestore-client';
import { Icon, IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Separator, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { NavLink } from 'react-router-dom';

import { useAppRoutes } from './Routes';
import { WorkspaceSwitcher } from './WorkspaceSwitcher.component';
import { auth } from '../firebase/app';
import { logout } from '../firebase/auth';

export const Sidebar: React.FC = () => {
  const [user] = useAuthState(auth);
  const Routes = useAppRoutes();
  const purgeWorkspace = useWorkspaceStore((w) => w.purge);

  return (
    <Layout.Row style={{ width: 240, height: '100vh', flexShrink: 0 }} bg="inputBackground">
      <Layout.Column grow>
        <WorkspaceSwitcher />

        <Separator.Horizontal />

        <Spacer.Vertical size={36} />

        <Layout.Column px={16} style={{ width: '100%' }}>
          {Routes.map((item) => {
            return (
              <NavLink to={item.route} key={item.route} style={{ textDecoration: 'none' }}>
                {({ isActive }) => {
                  return (
                    <Layout.Column>
                      <Layout.Row
                        bg={isActive ? 'grayXLight' : undefined}
                        radius={4}
                        py={4}
                        px
                        align
                      >
                        <Icon
                          name={isActive ? item.icon : ((item.icon + '-outline') as IconName)}
                          size={20}
                          color={isActive ? theme.colors.primary : theme.colors.gray}
                        />

                        <Spacer.Horizontal />

                        <OpenSans.Primary
                          weight={isActive ? 'bold-700' : 'regular-400'}
                          color={isActive ? 'primary' : 'gray'}
                          size="s-16"
                        >
                          {item.name}
                        </OpenSans.Primary>
                      </Layout.Row>
                      <Spacer.Vertical size={12} />
                    </Layout.Column>
                  );
                }}
              </NavLink>
            );
          })}
        </Layout.Column>

        <Spacer.Flex />
        {/* <Layout.Column px py>
        <Mono.Primary size={10}>{JSON.stringify({ Constants }, null, 2)}</Mono.Primary>
      </Layout.Column> */}

        <Layout.Column px py>
          <Spacer.Vertical />
          <Layout.PressableRow
            bg="primary"
            px
            py
            radius
            onClick={() => {
              purgeWorkspace();
              logout();
            }}
            center
            hoverColor={theme.colors.gray}
            border={[0, 'solid', 'gray']}
          >
            <OpenSans.Inverse weight="light-300">{user ? 'Log Out' : 'Log In'}</OpenSans.Inverse>
          </Layout.PressableRow>
        </Layout.Column>
      </Layout.Column>

      <Separator.Vertical />
    </Layout.Row>
  );
};
