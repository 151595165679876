import { currentUser } from '@easy-expense/auth-client';
import { useCachedMembers } from '@easy-expense/data-firestore-client';
import { UserRoleSchema } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { Layout } from '@easy-expense/ui-web-core';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import React from 'react';

import { AutoCompleteValue } from '../Shared/Dropdown/AutoCompleteTypes';
import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

type ShareReportParams = {
  createdBy: string;
  onSelected: (member: AutoCompleteValue | null) => void;
};

export const useMembersAutoComplete = (): AutoCompleteValue[] => {
  const members = useCachedMembers();

  return React.useMemo(() => {
    return [
      ...members.map((member) => {
        return {
          key: member.key,
          value: {
            name: getUserDisplayName(member, ''),
            image: member.profileImage,
            iconicIcon: 'person-circle-outline' as IconName,
          },
        };
      }),
    ];
  }, [members]);
};

export const ShareReport: React.FC<ShareReportParams> = ({ createdBy, onSelected }) => {
  const membersValues = useMembersAutoComplete();
  const members = useCachedMembers();

  const [selected, setSelected] = React.useState<string>(createdBy);
  function onSelectedMember(value: AutoCompleteValue | null) {
    if (value?.key) {
      setSelected(value.key);
      onSelected(value);
    }
  }
  const user = currentUser();
  const self = members?.find((mem) => mem.userID === user?.uid);
  const canNOTShare = self?.role === UserRoleSchema.Values.member;

  if (canNOTShare) {
    return null;
  }

  return (
    <Layout.Column>
      <SearchDropdown
        header={getTranslation('Share with')}
        placeholder={getTranslation('Member Name')}
        selectedKey={selected}
        autoCompleteValues={membersValues}
        onSelect={onSelectedMember}
        error={false}
        iconicIcon="people-circle-outline"
      />
    </Layout.Column>
  );
};
