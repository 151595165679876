import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

import { CategoriesHeader } from '../components/Categories/CategoriesHeader.component';
import { CategoriesTable } from '../components/Categories/CategoriesTable.component';

export const CategoriesPage: React.FC = () => {
  return (
    <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
      <Layout.Column style={{ minWidth: 600, width: '100%' }}>
        <CategoriesHeader />
        <CategoriesTable />
      </Layout.Column>
    </Layout.Column>
  );
};
