import { uploadBlob } from '@easy-expense/firebase-hooks';

import { convertPdfToImages } from './pdf';
import { auth } from '../../../firebase/app';

export type FirestorageFileUrl = string;

export async function uploadReceipts(files: FileList | null | undefined): Promise<string[]> {
  if (!files || !files.length) {
    return [];
  }
  const { pdfList, images } = getFileLists(files);
  const uploadedReceipts = [];

  uploadedReceipts.push(...(await uploadImages(images)));
  uploadedReceipts.push(...(await uploadPDFs(pdfList)));

  return uploadedReceipts;
}

async function uploadImages(images: File[]) {
  const receipts: string[] = [];
  for (const image of images) {
    const url = await uploadImage(image);
    if (!url) {
      continue;
    }
    receipts.push(url);
  }

  return receipts;
}

export async function uploadImage(file: File): Promise<string | undefined> {
  const currentUser = auth.currentUser;
  if (!currentUser?.uid) {
    return;
  }
  if (!isFileImage(file)) {
    return;
  }

  let uploadedFileUrl: string = '';
  try {
    uploadedFileUrl = await uploadBlob(file, currentUser.uid);
  } catch {
    console.error(`Failed to upload image ${file.name}`);
    alert('sorry we could not upload your file');
  }

  return uploadedFileUrl;
}

async function uploadPDFs(pdfList: File[]) {
  const receipts: string[] = [];

  for (const pdf of pdfList) {
    try {
      const imageUrls = await uploadPdfAsImage(pdf);

      if (imageUrls) {
        pdfList = pdfList.filter((p) => p.name !== pdf.name);
        for (const url of imageUrls) {
          receipts.push(url);
        }
      }
    } catch (error) {
      console.error(`Failed to upload pdf ${pdf.name}`);
      alert('sorry we could not upload your file');
    }
  }

  return receipts;
}

async function uploadPdfAsImage(file: File): Promise<string[] | undefined> {
  if (!isFilePdf(file)) {
    return;
  }
  const uploadedUrls: FirestorageFileUrl[] = [];

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (event) => {
      if (!event?.target?.result) {
        return;
      }
      const pdfData = new Uint8Array(event.target?.result as ArrayBuffer);
      let imageFiles: File[] = [];

      try {
        imageFiles = await convertPdfToImages(pdfData);
      } catch (err) {
        reject(new Error('Sorry we could not upload your pdf file.'));
      }

      if (!imageFiles?.length) {
        return;
      }

      for (const imageFile of imageFiles) {
        try {
          const url = await uploadImage(imageFile);
          if (url) {
            uploadedUrls.push(url);
          }
        } catch (_) {} // for now just skip any fails
      }

      resolve(uploadedUrls);
    };

    reader.readAsArrayBuffer(file);
  });
}

function getFileLists(files: FileList) {
  const pdfList: File[] = [];
  const images: File[] = [];

  for (const file of files) {
    if (isFilePdf(file)) {
      pdfList.push(file);
    } else if (isFileImage(file)) {
      images.push(file);
    } else {
      alert(`Sorry we don't support ${file.type} files.`);
    }
  }
  return { pdfList, images };
}

export function isFileImage(file: File) {
  return file.type.match(/(image\/png|image\/jpeg|image\/jpg)/) != null;
}

export function isFilePdf(file: File) {
  return file.type.match(/(application\/pdf)/) != null;
}
