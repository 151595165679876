import { Layout, Spacer } from '@easy-expense/ui-web-core';

export const BulkCheckbox: React.FC<{
  selected?: boolean;
  onSelect?: (state: boolean) => void;
}> = ({ selected, onSelect }) => {
  if (!onSelect) {
    return null;
  }

  return (
    <Layout.Row>
      <input
        type="checkbox"
        style={{ height: 16, width: 16 }}
        onChange={(event) => {
          onSelect(event.target.checked);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        checked={selected}
      />
      <Spacer.Horizontal size={8} style={{ flexShrink: 0 }} />
    </Layout.Row>
  );
};
