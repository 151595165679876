import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const DateField: React.FC<
  React.PropsWithChildren<{
    error?: boolean;
    initialDate?: string;
    onChange: (date: string) => void;
    disabled?: boolean;
    label?: string;
    hideIcons?: boolean;
  }>
> = ({ error, initialDate, onChange, disabled, label, hideIcons = false }) => {
  React.useEffect(() => {
    if (!initialDate) {
      const today = new Date().toLocaleDateString('en-ca');
      onChange(today);
    }
  }, []);

  return (
    <LabelTextField label={label ?? 'Date'} error={error} active={false}>
      <Layout.Row justify center style={{ width: '100%', position: 'relative' }}>
        {!hideIcons ? (
          <Icon name="calendar-outline" size={20} style={{ paddingRight: 10 }} />
        ) : null}

        <OpenSans.Input
          className="date-picker"
          name="date"
          value={initialDate}
          disabled={disabled}
          type="date"
          weight="light-300"
          placeholder="Today"
          style={{
            flexGrow: 1,
            outline: 'none',
            width: '100%',
            border: 'none',
            background: 'transparent',
            zIndex: 1,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        />

        {!hideIcons ? <Icon name="chevron-down" size={16} iconColor="primary" /> : null}
        <Spacer.Horizontal size={2} />
      </Layout.Row>
    </LabelTextField>
  );
};
