import { Layout, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';

export const Modal: React.FC<
  React.PropsWithChildren<{
    onClose?: () => void;
    setShowModal?: (showModal: boolean) => void;
    showModal?: boolean;
  }>
> = ({ showModal, children }) => {
  if (!showModal) {
    return null;
  }
  return (
    <Layout.Column
      center
      bg="shadow"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: zIndex.ModalOverlay,
      }}
    >
      <Layout.Column px={32} py={32} center radius bg="white">
        {children}
      </Layout.Column>
    </Layout.Column>
  );
};
