import { PaymentLink } from '@easy-expense/data-schema-v2';
import { useIntlStore } from '@easy-expense/intl-client';
import { OpenSans } from '@easy-expense/ui-web-core';

export const InvoiceHeader: React.FC<
  React.PropsWithChildren<{ invoice: Partial<PaymentLink> }>
> = ({ invoice }) => {
  const { formatCurrency } = useIntlStore();
  return (
    <>
      <OpenSans.Secondary>{invoice.desc}</OpenSans.Secondary>
      <OpenSans.Primary size={'xl-28'} weight="bold-700">
        {formatCurrency(invoice.total ?? 0)}
      </OpenSans.Primary>
    </>
  );
};
