import {
  getIssuingAccountOwner,
  useIsAdminRole,
  useIsManagerRole,
} from '@easy-expense/data-firestore-client';
import Data from '@easy-expense/frontend-data-layer';
import { IconName } from '@easy-expense/ui-shared-components';

export const Routes = [
  ...[
    { icon: 'receipt', name: 'Transactions', route: '/transactions' },
    { icon: 'car', name: 'Trips', route: '/trips' },
    { icon: 'bar-chart', name: 'Reports', route: '/reports' },
  ],
  ...(getIssuingAccountOwner() ? [{ icon: 'card', name: 'Card Issuing', route: '/issuing' }] : []),
  ...[{ icon: 'cloud-download', name: 'Export', route: '/export' }],
] as { icon: IconName; name: string; route: 'string' }[];

export const useAppRoutes = () => {
  const isAdmin = useIsAdminRole();
  const isManager = useIsManagerRole();
  const canManageTeam = isAdmin || isManager;
  const trips = Data.trips.use();

  return [
    ...[
      { icon: 'receipt', name: 'Transactions', route: '/transactions' },
      ...(trips.length > 0 ? [{ icon: 'car', name: 'Trips', route: '/trips' }] : []),
      { icon: 'bar-chart', name: 'Reports', route: '/reports' },
    ],
    ...(getIssuingAccountOwner()
      ? [{ icon: 'card', name: 'Card Issuing', route: '/issuing' }]
      : []),
    ...[{ icon: 'cloud-download', name: 'Export', route: '/export' }],
    ...(canManageTeam ? [{ icon: 'person-add', name: 'Manage Team', route: '/workspace' }] : []),
    ...[{ icon: 'hammer', name: 'Settings', route: '/settings' }],
  ] as { icon: IconName; name: string; route: 'string' }[];
};
