import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

export function useAwaitTask<T extends (...args: DoNotFixMeIAmAny[]) => Promise<DoNotFixMeIAmAny>>(
  task: T,
  onSuccess?: () => void,
  onError?: (e: DoNotFixMeIAmAny) => void,
): [T, boolean] {
  const [working, setWorking] = React.useState(false);
  const startTask = async (...args: DoNotFixMeIAmAny[]) => {
    try {
      setWorking(true);
      const taskResult = await task(...args);
      if (onSuccess) {
        onSuccess();
      }
      return taskResult;
    } catch (e) {
      if (onError) {
        onError(e);
      } else {
        console.error(e);
        alert('There was an error');
      }
    } finally {
      setWorking(false);
    }
  };
  return [startTask as typeof task, working];
}
