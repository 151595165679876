import { ReportStatus } from '@easy-expense/data-schema-v2';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { lighten } from 'polished';
import React from 'react';

import { LabelTextField } from '../LabelTextField.component';

const getStatusColor = (status?: ReportStatus) => {
  switch (status) {
    case 'Complete':
      return lighten(0.45, theme.colors.primary);
    case 'Sent':
      return lighten(0.45, theme.colors.brandPrimary);
    case 'Unsent':
      return lighten(0.45, theme.colors.success);
    default:
      return 'grayXLight';
  }
};

export const ReportStatusSelector: React.FC<{
  status?: ReportStatus;
  onChange: (status: ReportStatus) => void;
}> = ({ status, onChange }) => {
  const [statusColor, setStatusColor] = React.useState<string>(getStatusColor(status));

  const changeStatus = (status: ReportStatus) => {
    setStatusColor(getStatusColor(status));
    onChange(status);
  };

  return (
    <LabelTextField
      label="Status"
      active={false}
      styles={{
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      }}
    >
      <Layout.Column>
        <Layout.Column px py={4} radius={32} style={{ backgroundColor: statusColor }}>
          <Layout.Row style={{ fontFamily: 'Poppins_Regular', position: 'relative' }} align>
            <select
              value={status}
              onChange={(event) => changeStatus(event.target.value as ReportStatus)}
              style={{ border: 'none', width: '100%', background: 'transparent', zIndex: 1 }}
            >
              <option value="Unsent">📝 Active</option>
              <option value="Sent">📤 Sent</option>
              <option value="Complete">📤 Complete</option>
            </select>
          </Layout.Row>
        </Layout.Column>
      </Layout.Column>
    </LabelTextField>
  );
};
