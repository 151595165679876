import { Analytics } from '@easy-expense/analytics-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image } from 'react-native';

export const AppDownloadPage: React.FC = () => {
  return (
    <Layout.Column grow center px="2xl-32">
      <OpenSans.Primary size="3xl-38" weight="bold-700" center>
        Let's get started with Easy Expense!
      </OpenSans.Primary>
      <Spacer.Vertical />
      <OpenSans.Primary center>
        Please download the app on your phone to start scanning receipts 🚀
      </OpenSans.Primary>
      <Spacer.Vertical size={40} />
      <Layout.Row>
        <a
          className="badge-link"
          href="https://play.google.com/store/apps/details?id=com.easyexpense&amp;utm_source=marketing&amp;utm_medium=web"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            Analytics.track('app_download', { store: 'play-store' });
          }}
        >
          <Image
            source={require('../images/icons/google-play-badge.svg')}
            style={{ height: 40, width: 135 }}
          />
        </a>
        <Spacer.Horizontal />
        <a
          className="badge-link"
          href="https://apps.apple.com/app/apple-store/id1528787066?pt=122013942&amp;ct=marketingweb&amp;mt=8"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            Analytics.track('app_download', { store: 'app-store' });
          }}
        >
          <Image
            source={require('../images/icons/app-store-badge.svg')}
            style={{ height: 40, width: 135 }}
          />
        </a>
      </Layout.Row>
    </Layout.Column>
  );
};
